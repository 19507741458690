import {ToastifyCaller, ToastifyType} from '../components/Toastify'
import {InvoicePackingListResponse, Order} from '../models/allOrdersWithFilters'
import {MyOrder} from '../models/myOrders'
import {orderInvoice, orderPackagingList} from '../requests/requests'
import {pdfWindowLauncher} from './pdfWindowLauncher'

export const generateInvoicePDF = async (
  setGeneratingPDF: React.Dispatch<React.SetStateAction<number>>,
  setGeneratingOrderPDF: React.Dispatch<React.SetStateAction<string>>,
  myOrder: MyOrder | Order,
  invoiceId: string
) => {
  setGeneratingPDF(1)
  setGeneratingOrderPDF(invoiceId)
  try {
    const response = await orderInvoice(myOrder.guidId, invoiceId)
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
    if (response.data.haveErrors) {
      ToastifyCaller(response.data.errorMessage, ToastifyType.ERROR)
    } else {
      const invoiceResponse = response.data as InvoicePackingListResponse
      pdfWindowLauncher(invoiceResponse.base64)
    }
  } catch (Exception) {
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
  }
}

export const generatePackagingListPDF = async (
  setGeneratingPDF: React.Dispatch<React.SetStateAction<number>>,
  setGeneratingOrderPDF: React.Dispatch<React.SetStateAction<string>>,
  myOrder: MyOrder | Order,
  invoiceId: string
) => {
  setGeneratingPDF(2)
  setGeneratingOrderPDF(invoiceId)
  try {
    const response = await orderPackagingList(myOrder.guidId, invoiceId)
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
    if (response.data.haveErrors) {
      ToastifyCaller(response.data.errorMessage, ToastifyType.ERROR)
    } else {
      const invoiceResponse = response.data as InvoicePackingListResponse
      pdfWindowLauncher(invoiceResponse.base64)
    }
  } catch (Exception) {
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
  }
}

export const generateKeyFromString = (str) => {
  return str
    .toUpperCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '')
}

export const PasswordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/gm
export const ShowPackagingListOrInvoiceInOrderList = false

export const ToNormalizedState = (state: string) => {
  if (state === 'Facturado') {
    state = 'Invoiced'
  }
  if (state === 'Enviado') {
    state = 'Sent'
  }
  if (state === 'Recibido') {
    state = 'Received'
  }
  if (state === 'Procesando') {
    state = 'Processing'
  }
  return state
}

export const IsFrozenProduct = (productCode) => {
  return productCode.startsWith('ACA') || productCode.startsWith('ACAB')
}
