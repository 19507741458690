import {Product} from '../models/models'
import {useQuery} from 'react-query'
import {requestProducts} from '../requests/requests'

type ProductsAvailablesData = {
  availableProducts: Product[]
  refetchProducts: any
}

export const useProductsAvailables = (clientID: string, date: Date): ProductsAvailablesData => {
  const productsKey = `products${clientID}`
  const {refetch: refetchProducts} = useQuery<Product[]>(
    [`availableProducts${clientID}`],
    async () => {
      const {data: x} = await requestProducts(clientID, date)
      x.forEach((p: Product) => {
        if (p.urlImage == null) {
          p.urlImage =
            'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
        }
      })
      if (x && x.length > 0) {
        x.sort((value) => {
          return value.inPromotion ? -1 : 1
        })
        localStorage.setItem(productsKey, JSON.stringify(x))
      }
      return x
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!clientID}
  )
  if (!clientID) {
    return {
      availableProducts: [] as Product[],
      refetchProducts: refetchProducts,
    } as ProductsAvailablesData
    //return [[] as Product[], refetchProducts]
  } else {
    const cacheProducts = localStorage.getItem(productsKey)
    if (cacheProducts) {
      const availableProducts: Product[] = JSON.parse(localStorage.getItem(productsKey) as string)
      return {
        availableProducts: availableProducts,
        refetchProducts: refetchProducts,
      } as ProductsAvailablesData
      //return [availableProducts, refetchProducts]
    }
    return {
      availableProducts: [] as Product[],
      refetchProducts: refetchProducts,
    } as ProductsAvailablesData
    //return [[] as Product[], refetchProducts]
  }
}
