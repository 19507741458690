import {createContext, useContext, useState} from 'react'
import {CartItem} from '../models/models'
import {LeaveNavigationWatcher} from '../routing/LeaveNavigationWatcher'

type ShoppingCartContextFn = {
  cartItems: CartItem[]
  setCartItems: (items: CartItem[]) => void
  removeItem: (code: string, stockRatio: number) => void
  clearCart: () => void
  cartPricing: any
  setCartPricing: any
  isAddingToCart: string
  setIsAddingToCart: any
}

const ShoppingCartContext = createContext({} as ShoppingCartContextFn)

export function useShoppingCart() {
  const context = useContext(ShoppingCartContext)

  if (!context) {
    throw new Error('useShoppingCart must be used within a ShoppingCartProvider')
  }

  return context
}

export function ShoppingCartProvider({children}) {
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [cartPricing, setCartPricing] = useState<boolean>(false)
  const [isAddingToCart, setIsAddingToCart] = useState<string>('')

  function removeItem(Code: string, StockRatio: number) {
    setCartItems((prev) =>
      prev.filter((item) => !(item.code === Code && item.stockRatio === StockRatio))
    )
  }

  function clearCart() {
    setCartItems([])
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        setCartItems,
        removeItem,
        clearCart,
        cartPricing,
        setCartPricing,
        isAddingToCart,
        setIsAddingToCart,
      }}
    >
      <LeaveNavigationWatcher>{children}</LeaveNavigationWatcher>
    </ShoppingCartContext.Provider>
  )
}
