/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Link } from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import {useShoppingCart} from '../../../../app/context/ShoppingCartContext'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Product} from '../../../../app/models/models'
import {useProductsAvailablesForSelectedClient} from '../../../../app/hooks/useProductsAvailablesForSelectedClient'
import { useSelectedExpeditionDate } from '../../../../app/hooks/useSelectedExpeditionDate'

const CartDrawer = () => {
  const intl = useIntl()
  const selectedExpeditionDate = useSelectedExpeditionDate();
  const {availableProducts} = useProductsAvailablesForSelectedClient(selectedExpeditionDate)
  const {cartItems, removeItem, clearCart} = useShoppingCart()
  const productInfo = (code: string): Product => {
    const products: Product[] =
      availableProducts && availableProducts?.filter((product) => product.code === code)
    return availableProducts && products[0]
  }

  return (
    <section
      id='kt_cart'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_cart_toggle'
      data-kt-drawer-close='#kt_cart_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <header className='card-header' id='kt_cart_header'>
          <h2 className='card-title fw-bold text-gray-600'>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_TITLE'})}
          </h2>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_cart_close'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </header>
        {/* end::Header */}

        {/* begin::Body */}
        <main className='card-body' id='kt_cart_body'>
          {/* begin:: Dynamic content*/}
          <ul className='d-flex flex-column mb-10 list-unstyled gap-1'>
            {cartItems &&
              cartItems.map((item) => {
                if (!productInfo(item.code)) return <></>
                return (
                  <li
                    key={item.code + '-' + item.stockRatio}
                    className='d-flex align-items-center justify-content-between'
                  >
                    <article
                      className='d-flex align-items-center justify-content-between gap-4 w-100 py-6 px-4 rounded'
                      style={{border: '1px dotted #f1f1f2'}}
                      key={productInfo(item.code).code + '-' + productInfo(item.code).stockRatio}
                    >
                      <div className='d-flex gap-4 align-items-center'>
                        <img
                          src={productInfo(item.code).urlImage}
                          alt={`${productInfo(item.code).description} - ${item.stockRatio}`}
                          className='rounded'
                          width={50}
                          height={50}
                        />
                        <div className='d-flex flex-column justify-content-center'>
                          <h3 className=' fs-6'>{productInfo(item.code).description}</h3>
                          <span>
                            <strong>
                              {intl.formatMessage({id: 'CART.ITEM_PACKAGE_FORMAT'})}:{' '}
                            </strong>
                            {(item.packageUnit === 'PZA' || item.packageUnit === 'CAJ') && (
                              <span>
                                {item.stockRatio}{' '}
                                {intl.formatMessage({id: 'CART.ITEM_FORMAT_KG_PIECE'})}
                              </span>
                            )}
                            {item.packageUnit.startsWith('c') && (
                              <span>{item.stockRatio} / UN</span>
                            )}
                          </span>
                          <span>
                            {
                              <span>
                                <strong>{intl.formatMessage({id: 'CART.ITEM_QUANTITY'})}: </strong>
                                {item.quantity}
                              </span>
                            }
                          </span>
                        </div>
                      </div>
                      <button
                        className='btn pe-0'
                        onClick={() => removeItem(item.code, item.stockRatio)}
                      >
                        {/* <KTSVG
                        path='media/icons/duotune/general/gen040.svg'
                        className='svg-icon-danger svg-icon-2hx cursor-pointer'
                      /> */}
                        <span className='text-black-50'>Eliminar</span>
                      </button>
                    </article>
                  </li>
                )
              })}
            {cartItems.length === 0 && (
              <span className='fw-bolder text-gray-500 mx-4'>
                {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_EMPTY'})}
              </span>
            )}
          </ul>
          <Button onClick={() => clearCart()}>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_CLEAR'})}
          </Button>
          {/* end:: Dynamic content*/}
        </main>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </section>
  )
}

export {CartDrawer}
