import React, { ReactNode } from "react"

interface ErrorCatcherProps {
  children: ReactNode;
}

interface ErrorCatcherState {
  hasError: boolean;
  error: Error;
}

export default class ErrorCatcher extends React.Component<ErrorCatcherProps, ErrorCatcherState> {
    state = { hasError: false, error: new Error('Unknown') };
    constructor(props) {
        super(props)
    }
    static getDerivedStateFromError(error:Error) {
        return { hasError: true, error };
      }
    render() {
        if (this.state.hasError) {
          return <h1>{this.state.error.stack ? this.state.error.stack : ''}.</h1>;
        }
    
        return this.props.children;
    }
}