import {useState} from 'react'
import {Button, Col, Image, Row} from 'react-bootstrap'
import {deleteNews} from '../../requests/requests'
import {useIntl} from 'react-intl'
import EditNewsModal from '../../components/edit-news/editNewsModal'
import {ConfirmDeleteModal} from '../../components/generic-confirm/Confirm'
import {Link} from 'react-router-dom'
import {QuillEditor} from '../../components/QuillEditor'

function SingleNews({title, image, content, id, enable, lang, date, externalLink, refetchNews}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editorValue, setEditorValue] = useState(content)
  const [editShow, setEditShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const intl = useIntl()

  const handleConfirmDeleteNews = async () => {
    await deleteNews(id)
    await refetchNews()
    setDeleteShow(false)
  }

  return (
    <article className='col-12 col-md-6 col-xl-4 mb-6' id={id} key={id}>
      <div className='d-flex flex-column w-100 h-100 align-self-stretch'>
        <div className='d-flex justify-content-between align-items-start mb-4 gap-4'>
          <div className='d-flex flex-column'>
            <span>
              <strong>Language: </strong> {lang === 0 ? 'English' : 'Spanish'}
            </span>
            <label>
              <strong>Enabled: </strong> {enable ? 'Yes' : 'No'}
            </label>
          </div>
          <div className='d-flex gap-2'>
            <Button
              className='btn btn-sm btn-primary'
              data-toggle='tooltip'
              title={intl.formatMessage({id: 'BUTTON.EDIT'})}
              onClick={() => setEditShow(true)}
            >
              {intl.formatMessage({id: 'BUTTON.EDIT'})}
            </Button>
            <Button
              className='btn btn-sm btn-secondary'
              data-toggle='tooltip'
              title={intl.formatMessage({id: 'BUTTON.DELETE'})}
              onClick={() => {
                setDeleteShow(true)
              }}
            >
              {intl.formatMessage({id: 'BUTTON.DELETE'})}
            </Button>
          </div>
        </div>
        <Row>
          <Col>
            <Image
              className='mb-3 bg-secondary rounded'
              fluid
              src={image}
              alt={title}
              style={{aspectRatio: '16/9', objectFit: 'cover', height: '305px'}}
            />
            <span className='text-black-60 fw-medium text-muted d-flex align-items-center justify-content-end mb-3'>
              {new Date(date).toLocaleDateString('en-GB')}
            </span>
            <h3 className='lh-base'>{title}</h3>
            <div className='text-black-50 fs-5 fw-medium lh-lg'>
              <QuillEditor value={content} setValue={setEditorValue} readOnly={true} />
            </div>
            <EditNewsModal
              id={id}
              title={title}
              description={content}
              image={image}
              enable={enable}
              lang={lang}
              externalLink={externalLink}
              show={editShow}
              onHide={() => setEditShow(false)}
              refetchNews={refetchNews}
            />
            {externalLink && (
              <Link to={externalLink} target='_blank'>
                <Button
                  type='button'
                  variant='primary'
                  className='mt-5 w-100 w-lg-50 mx-auto d-block'
                >
                  {intl.formatMessage({id: 'BUTTON.READ_MORE'})}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </div>
      <ConfirmDeleteModal
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
        onConfirm={handleConfirmDeleteNews}
        title={intl.formatMessage({id: 'CONFIRM_TEXT'})}
        message={intl.formatMessage({id: 'BUTTON.CONFIRM_DELETE'})}
      />
    </article>
  )
}

export default SingleNews
