import { Button, Modal } from 'react-bootstrap';
import {useIntl} from 'react-intl';


export const ConfirmNotificationModal = ({show, setShow}) => {
  const intl = useIntl();

  return (
    <Modal show={show} dialogClassName='modal-dialog modal-dialog-centered modal-lg'>
      <div className={`alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0`}>
        <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
          <i className='bi bi-check-circle text-success fs-4x'></i>
        </span>

        <div className='text-center'>
          <h5 className='fw-bolder fs-1 mb-5'>
            {intl.formatMessage({id: 'CONFIRM_NOTIFICATION_TITLE'})}
          </h5>

          <div className='mb-9 fs-4'>
            {intl.formatMessage({id: 'CONFIRM_NOTIFICATION_SUBTITLE'})}
          </div>

          <Button onClick={() => {setShow(false);localStorage.removeItem('orderSended');}}>{intl.formatMessage({id: 'BUTTON.ACCEPT'})}</Button>
        </div>
      </div>

    </Modal>
  );
};
