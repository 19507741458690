/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  topClients: TopClientItem[]
}

export type TopClientItem = {
  clientBusinessName: string
  finalAmount: number
}

export const TopClientsChart: React.FC<Props> = ({
  className,
  topClients,
}: {
  className: string
  topClients: TopClientItem[]
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  const demoMode = false

  // demo
  if (demoMode) {
    for (let a = 1; a < 10; a++) {
      const exampleTop = {...topClients[0]}
      exampleTop.finalAmount -= a * 250
      exampleTop.clientBusinessName = 'Empresa Random ' + a
      topClients.push(exampleTop)
    }
  }

  // sort
  topClients.sort((a: TopClientItem, b: TopClientItem) => {
    return b.finalAmount - a.finalAmount
  })

  useEffect(() => {
    const getChartOptions = (height: number): ApexOptions => {
      const labelColor = getCSSVariableValue('--bs-gray-500')
      const borderColor = getCSSVariableValue('--bs-gray-200')
      const baseColor = getCSSVariableValue('--bs-warning')
      const secondaryColor = getCSSVariableValue('--bs-gray-300')

      return {
        series: [
          {
            name: intl.formatMessage({id: 'DASHBOARD.TOP_CLIENTS_CHART.LABEL_CHART'}),
            data: topClients.map((x) => x.finalAmount),
          },
        ],
        chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: height,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            borderRadius: 5,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: topClients.map((x) => x.clientBusinessName),
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val + '€'
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      }
    }
    if (chartRef) {
      if (!chartRef.current) {
        return
      }
      const height = parseInt(getCSS(chartRef.current, 'height'))
      const chart = new ApexCharts(chartRef.current, getChartOptions(height))
      if (chart) {
        chart.render()
      }
      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }
  }, [chartRef, intl, topClients])

  return (
    <div className={`card h-100 ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'DASHBOARD.TOP_CLIENTS_CHART.TITLE'})}
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {intl.formatMessage({id: 'DASHBOARD.TOP_CLIENTS_CHART.SUBTITLE'})}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {topClients.length <= 0 && (
          <span className='text-muted'>
            {intl.formatMessage({id: 'GENERAL.NO_DATA_AVAILABLE'})}
          </span>
        )}
        {topClients.length > 0 && (
          <>
            {/* begin::Chart */}
            <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '450px'}}></div>
            {/* end::Chart */}
          </>
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}
