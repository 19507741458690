import {createContext, useContext, useState} from 'react'
import {CartItem} from '../models/models'

type AdminShoppingCartContextFn = {
  cartItems: CartItem[]
  setCartItems: (items: CartItem[]) => void
  removeItem: (code: string, stockRatio: number) => void
  clearCart: () => void
  isAddingToCart: string
  setIsAddingToCart: any
}

const AdminShoppingCartContext = createContext({} as AdminShoppingCartContextFn)

export function useAdminShoppingCart() {
  const context = useContext(AdminShoppingCartContext)

  if (!context) {
    throw new Error('useAdminShoppingCart must be used within a AdminShoppingCartContext')
  }

  return context
}

export function AdminShoppingCartProvider({children}) {
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [isAddingToCart, setIsAddingToCart] = useState<string>('')

  function removeItem(Code: string, StockRatio: number) {
    setCartItems((prev) =>
      prev.filter((item) => !(item.code === Code && item.stockRatio === StockRatio))
    )
  }

  function clearCart() {
    setCartItems([])
  }

  return (
    <AdminShoppingCartContext.Provider
      value={{cartItems, setCartItems, removeItem, clearCart, setIsAddingToCart, isAddingToCart}}
    >
      {children}
    </AdminShoppingCartContext.Provider>
  )
}
