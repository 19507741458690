import {Button, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import {confirmOrders, requestDeleteOrder} from '../../../requests/requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {OrderStatus, PendingOrder} from '../../../models/models'
import {formatDate} from '../../../helpers/formatDate'
import {useNavigateHelper} from '../../../hooks/useNavigateHelper'
import {ConfirmDeleteModal} from '../../../components/generic-confirm/Confirm'
import {Dispatch, useState} from 'react'
import {useBtnTitles} from '../../../hooks/useBtnTitles'
import {IsFrozenProduct} from '../../../helpers/utils'

export default function ValidateOrdersRow(
  {
    order,
    refetch,
    setOrdersSelection,
    validatingOrder,
    setValidatingOrder,
    validatingIndividualOrder,
    setValidatingIndividualOrder,
  }: {
    order: PendingOrder
    refetch: any
    setOrdersSelection: any
    validatingOrder: boolean
    setValidatingOrder: any
    validatingIndividualOrder: string
    setValidatingIndividualOrder: Dispatch<React.SetStateAction<string>>
  },
  key
) {
  const intl = useIntl()
  const btnTitles = useBtnTitles()
  const location = useLocation()
  const navigate = useNavigateHelper()
  const [deleteShow, setDeleteShow] = useState(false)

  const validateOrder = async () => {
    setValidatingIndividualOrder(order.guidId)
    const confirmedOrders = await confirmOrders([order.guidId])
    localStorage.setItem('ordersValidated', JSON.stringify(confirmedOrders.data))
    setValidatingIndividualOrder('')
    await refetch()
    navigate(location.pathname)
  }

  const handleConfirmDelete = async (orderId: string) => {
    await requestDeleteOrder(orderId)
    await refetch()
  }

  return (
    <tr key={key} data-id={order.guidId} id={`tableOrderRow${order.guidId}`}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          {order.status === OrderStatus.Pending && (
            <input className='form-check-input' type='checkbox' onChange={setOrdersSelection} />
          )}
          <ConfirmDeleteModal
            show={deleteShow}
            onHide={() => setDeleteShow(false)}
            onConfirm={() => {
              handleConfirmDelete(order.guidId)
            }}
            title={intl.formatMessage({id: 'CONFIRM_TEXT'})}
            message={intl.formatMessage({id: 'BUTTON.CONFIRM_DELETE'})}
          />
        </div>
      </td>
      <td className='text-center'>
        <span className='text-dark fw-bold d-block fs-6'>
          {new Date(order.dateCreated).toLocaleDateString('en-GB')}
        </span>
      </td>
      <td className='text-center'>
        <span className='text-dark fw-bold d-block fs-6'>{`${order.customer?.businessName}`}</span>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {`${order.detail?.clientBusinessName}`}
        </span>
      </td>
      <td className='text-center'>
        <span className='text-dark fw-bold d-block fs-6'>
          {order.detail.shippingAddressDescription} ({order.detail.shippingAddressSageCode})
        </span>
      </td>
      <td className='text-center'>
        <span className='text-dark fw-bold d-block fs-6'>{formatDate(order.expeditionDate)}</span>
      </td>
      <td className='text-center'>
        <span
          className={`badge ${
            order.status === OrderStatus.Pending ? 'badge-light-warning' : 'badge-light-success'
          }`}
        >
          <span>
            {intl.formatMessage({id: 'ORDER.STATUS.' + OrderStatus[order.status].toUpperCase()})}
          </span>
        </span>
      </td>
      <td className='text-center'>
        <Link
          to={`/order-detail/${order.guidId}`}
          className='d-inline-flex flex-items-center justify-content-center'
          title={btnTitles.details}
          onClick={() => {
            localStorage.setItem('lastOrderView', order.guidId)
          }}
        >
          <KTIcon iconName='eye' className='fs-3' />
        </Link>
        {order.status === OrderStatus.Pending && (
          <>
            <Link
              to={`/order-edit/${order.guidId}`}
              className='d-inline-flex flex-items-center justify-content-center ms-3'
              title={btnTitles.edit}
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </Link>
            <Link
              to={`#`}
              className='d-inline-flex flex-items-center justify-content-center ms-3'
              title={btnTitles.delete}
              onClick={() => setDeleteShow(true)}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </Link>
          </>
        )}
        {order.status === OrderStatus.Pending &&
          order.sageResponse &&
          order.sageResponse.length > 0 && (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  <div>{order.sageResponse}</div>
                  {/* <div>
                {order.lines.map((item:Line, index: number) => (
                  <div className="d-flex flex-column" key={`order-validate-line-${order.id}-${index}`}>
                    <strong key={index}>{item.description}</strong>
                    <span>
                      {
                        `${item.stockUnit === 'KG' 
                        ? `${item.quantity} ${item.packageUnit} ${item.stockRatio} ${item.stockUnit}` 
                        : `${item.quantity} ${item.stockUnit} ${item.packageUnit}`}`
                      }        
                    </span>              
                  </div>
                ))}
              </div> */}
                </Tooltip>
              }
            >
              <span className='d-inline-flex flex-items-center justify-content-center ms-3'>
                <KTIcon iconName='information-4' className='fs-3 text-danger' iconType='solid' />
              </span>
            </OverlayTrigger>
          )}
      </td>
      <td className='text-end'>
        {order.lines.find((x) => IsFrozenProduct(x.code)) && (
          <div
            className='d-inline-flex me-3'
            title={intl.formatMessage({id: 'GENERAL.FROZEN_PRODUCT'})}
          >
            <i className='bi bi-snow2 text-primary'></i>
          </div>
        )}
        {order.status === OrderStatus.Pending && (
          <Button
            className={`btn btn-primary ${validatingOrder ? 'disabled' : ''} ${
              validatingIndividualOrder.length > 0 ? 'disabled' : ''
            }`}
            onClick={validateOrder}
          >
            <div className='d-flex align-items-center justify-content-center'>
              {intl.formatMessage({id: 'VALIDATE_ORDERS.ROW_VALIDATE'})}
              {validatingIndividualOrder === order.guidId && (
                <Spinner animation='border' className='mx-3'></Spinner>
              )}
              {validatingOrder && <Spinner animation='border' className='mx-3'></Spinner>}
            </div>
          </Button>
        )}
      </td>
    </tr>
  )
}
