import {Container, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {requestProfile} from '../../requests/requests'
import {useQuery} from 'react-query'
import {UserProfile as UserProfileObj} from '../../models/models'
import {Field, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {requestEditProfile, requestPasswordChange} from '../../modules/auth/core/_requests'
import {ChangePassword, EditProfile, useAuth} from '../../modules/auth'
import {ToastifyCaller, ToastifyType} from '../../components/Toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {useState} from 'react'
import {UpdateSageCodeInput} from '../../components/UpdateSageCodeInput'
import {PasswordRegex} from '../../helpers/utils'
import {
  useValidationAPITranslations,
  useValidationTranslations,
} from '../../helpers/useValidationTranslations'

function UserProfile() {
  const intl = useIntl()
  const {auth} = useAuth()
  const [showEditSageCode, setShowEditSageCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {data: dataUser} = useQuery<UserProfileObj>(
    ['myProfile'],
    async () => {
      const {data: x} = await requestProfile()
      return x
    },
    {staleTime: 60 * 60 * 24 * 365}
  )
  const handleChangePasswordSubmit = async (values: FormikValues) => {
    try {
      const result = await requestPasswordChange(values as ChangePassword)
      const resultOk: boolean = result.status >= 200 && result.status < 300
      if (resultOk) {
        ToastifyCaller(
          intl.formatMessage({id: 'USER_PROFILE.PASSWORD_CHANGED_OK'}),
          ToastifyType.SUCCESS
        )
      }
    } catch (err: any) {
      const errorMessageFromAPI: string =
        err && err['response'] && err['response']['data']['message']
      setErrorMessage(errorMessageFromAPI)
    }
  }

  const handleChangeSageCode = (e) => {
    e.preventDefault()
    setShowEditSageCode(true)
  }

  const handleEditSageCode = async (values: FormikValues) => {
    const editProfileObj: EditProfile = dataUser as EditProfile
    editProfileObj.sageCode = values.sageCode
    await requestEditProfile(editProfileObj)
    setShowEditSageCode(false)
  }

  const {
    MessagePasswordRequired,
    MessagePasswordRequiredFormat,
    MessagePasswordConfirmationRequired,
    MessagePasswordLength,
  } = useValidationTranslations()

  const translateApiStr = useValidationAPITranslations()

  return (
    <Container>
      <div className='d-flex justify-content-between mb-8'>
        <div>
          <h1>{intl.formatMessage({id: 'USER_PROFILE.TITLE'})}</h1>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            {intl.formatMessage({id: 'USER_PROFILE.SUBTITLE'})}
          </span>
        </div>
      </div>
      <Row>
        <div className='col-12 col-lg-6'>
          <article className='card mb-5 mb-xxl-8'>
            <div className='card-body'>
              <h2>{intl.formatMessage({id: 'USER_PROFILE.USER_DATA.TITLE'})}</h2>
              <div className='separator my-10'></div>
              <div className='d-flex flex-column gap-10 fs-6 fw-medium'>
                {/* NAME */}
                <div style={{display: 'grid', gridTemplateColumns: '1fr 2fr'}}>
                  <div className='text-lightgrey'>
                    {intl.formatMessage({id: 'USER_PROFILE.USER_DATA.USERNAME'})}
                  </div>
                  <div>{dataUser?.businessName}</div>
                </div>
                {/* EMAIL */}
                <div style={{display: 'grid', gridTemplateColumns: '1fr 2fr'}}>
                  <div className='text-lightgrey'>
                    {intl.formatMessage({id: 'USER_PROFILE.USER_DATA.EMAIL'})}
                  </div>
                  <div>{dataUser?.email}</div>
                </div>
                {/* SAGE CODE */}
                <div style={{display: 'grid', gridTemplateColumns: '1fr 2fr'}}>
                  <div className='text-lightgrey'>
                    {intl.formatMessage({id: 'USER_PROFILE.USER_DATA.SAGE_CODE'})}
                  </div>
                  <div>
                    <UpdateSageCodeInput
                      defaultValue={dataUser?.sageCode}
                      editMode={showEditSageCode}
                      onSubmit={handleEditSageCode}
                    />
                    {auth?.isAdmin && (
                      <span
                        onClick={handleChangeSageCode}
                        className={`cursor-pointer ${showEditSageCode ? 'd-none' : ''}`}
                      >
                        <KTIcon iconName='pencil' className='ms-3'></KTIcon>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div className='col-12 col-lg-6'>
          <article className='card mb-5 mb-xxl-8'>
            <div className='card-body'>
              <h2>{intl.formatMessage({id: 'USER_PROFILE.PASSWORD.TITLE'})}</h2>
              <div className='separator my-10'></div>
              <div className='d-flex flex-column'>
                {/* NAME */}
                <span className='fs-7 mb-10 text-lightgrey'>
                  {intl.formatMessage({id: 'USER_PROFILE.PASSWORD.INFO_TEXT'})}
                </span>
                <Formik
                  validationSchema={Yup.object({
                    currentPassword: Yup.string().required(),
                    password: Yup.string()
                      .min(10, MessagePasswordLength)
                      .matches(PasswordRegex, MessagePasswordRequiredFormat)
                      .required(MessagePasswordRequired),
                    confirmPassword: Yup.string()
                      .min(10, MessagePasswordLength)
                      .matches(PasswordRegex, MessagePasswordRequiredFormat)
                      .required(MessagePasswordConfirmationRequired),
                  })}
                  initialValues={{
                    currentPassword: '',
                    password: '',
                    confirmPassword: '',
                    success: false,
                  }}
                  onSubmit={handleChangePasswordSubmit}
                >
                  {({errors, touched}) => (
                    <Form className='d-flex flex-column gap-10 fs-6 fw-medium' noValidate placeholder={undefined}>
                      <label htmlFor='currentPassword' className='d-flex flex-column gap-3'>
                        <span>
                          {intl.formatMessage({id: 'USER_PROFILE.PASSWORD.CURRENT_PASSWORD'})}
                        </span>
                        <Field
                          type='password'
                          className='form-control bg-transparent'
                          name='currentPassword'
                          id='currentPassword'
                        />
                      </label>
                      <label htmlFor='password' className='d-flex flex-column gap-3'>
                        <span>
                          {intl.formatMessage({id: 'USER_PROFILE.PASSWORD.NEW_PASSWORD'})}
                        </span>
                        <Field
                          type='password'
                          className='form-control bg-transparent'
                          name='password'
                          id='password'
                        />
                      </label>
                      <label htmlFor='confirmPassword' className='d-flex flex-column gap-3'>
                        <span>
                          {intl.formatMessage({id: 'USER_PROFILE.PASSWORD.CONFIRM_NEW_PASSWORD'})}
                        </span>
                        <Field
                          type='password'
                          className='form-control bg-transparent'
                          name='confirmPassword'
                          id='confirmPassword'
                        />
                      </label>
                      {errors &&
                        ((errors.password && touched.password) ||
                          (errors.confirmPassword && touched.confirmPassword)) && (
                          <div className='alert alert-info'>
                            <span className='fs-7 mb-10'>
                              {intl.formatMessage({
                                id: 'USER_PROFILE.PASSWORD.INFO_TEXT_FORMAT_REQUIRED',
                              })}
                            </span>
                          </div>
                        )}
                      {errorMessage && (
                        <div className='alert alert-danger'>
                          <span className='fs-7 mb-10'>{translateApiStr(errorMessage)}</span>
                        </div>
                      )}
                      <button
                        type='submit'
                        className='btn btn-primary py-4 my-3'
                        style={{width: 'fit-content'}}
                      >
                        {intl.formatMessage({id: 'BUTTON.ACCEPT'})}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </article>
        </div>
      </Row>
    </Container>
  )
}

export {UserProfile}
