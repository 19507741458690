import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {useState} from 'react'
import {useBtnTitles} from '../../hooks/useBtnTitles'

interface ConfirmationModalProps {
  onConfirm: () => void
  title: string
  message: string
  type?: string
}

export const ConfirmDeleteModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  title,
  message,
  type = 'error',
}) => {
  const intl = useIntl()
  const [show, setShow] = useState<boolean>(false)
  const [deleting, setDeleting] = useState(false)
  const btnTitles = useBtnTitles()

  return (
    <>
      <Link
        to={`#`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        title={btnTitles.delete}
        onClick={() => setShow(true)}
      >
        <KTIcon iconName='trash' className='fs-3' />
      </Link>
      <Modal
        show={show}
        onHide={() => {
          setDeleting(false)
          setShow(false)
        }}
        dialogClassName='modal-dialog modal-dialog-centered'
      >
        <div
          className={`alert alert-dismissible ${
            type === 'error' ? 'bg-light-danger' : ''
          } d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0`}
        >
          {type === 'error' && (
            <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
              <i className='bi bi-x-circle text-danger fs-4x'></i>
            </span>
          )}

          <div className='text-center'>
            <h5 className='fw-bolder fs-1 mb-5'>{title}</h5>

            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

            <div className='mb-9 fs-4'>{message}</div>
            <div className='d-flex flex-center flex-wrap'>
              <Button
                variant='danger'
                data-toggle='tooltip'
                title={intl.formatMessage({id: 'BUTTON.YES_DELETE'})}
                onClick={() => {
                  setDeleting(true)
                  onConfirm()
                }}
                className={`btn btn-danger m-2 ${deleting ? 'disabled' : ''}`}
              >
                {intl.formatMessage({id: 'BUTTON.YES_DELETE'})}
              </Button>
              <Button
                variant='secondary'
                data-toggle='tooltip'
                title={intl.formatMessage({id: 'BUTTON.NO_CANCEL'})}
                className={`btn btn-outline btn-outline-danger btn-active-danger m-2 ${
                  deleting ? 'disabled' : ''
                }`}
                onClick={() => {
                  setDeleting(false)
                  setShow(false)
                }}
              >
                {intl.formatMessage({id: 'BUTTON.NO_CANCEL'})}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
