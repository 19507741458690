import { Modal, Button } from 'react-bootstrap';
import {useIntl} from 'react-intl';

interface ConfirmationModalProps {
  show: boolean
  onCancel: () => void
  onConfirm: () => void
  type?: string
}

export const ConfirmLeaveModal: React.FC<ConfirmationModalProps> = ({ show, onCancel, onConfirm }) => {
  const intl = useIntl();

  return (
    <Modal show={show} dialogClassName='modal-dialog modal-dialog-centered modal-lg'>
      <div className={`alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0`}>
        <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
          <i className='bi bi-exclamation-circle text-danger fs-4x'></i>
        </span>

        <div className='text-center'>
          <h5 className='fw-bolder fs-1 mb-5'>
            {intl.formatMessage({id: 'CONFIRM_LEAVE.TITLE'})}
          </h5>

          <div className='mb-9 fs-4'>
            {intl.formatMessage({id: 'CONFIRM_LEAVE.SUBTITLE'})}
          </div>
          <div className='d-flex flex-center flex-wrap'>
            <Button
              variant='primary'
              data-toggle='tooltip'
              title={intl.formatMessage({id: 'CONFIRM_LEAVE.CANCEL'})}
              onClick={onConfirm}
              className="m-2"
            >
              {intl.formatMessage({id: 'CONFIRM_LEAVE.CANCEL'})}
            </Button>
            <Button
              variant='secondary'
              data-toggle='tooltip'
              title={intl.formatMessage({id: 'CONFIRM_LEAVE.CONTINUE'})}
              onClick={onCancel}
              className='btn-outline btn-outline-primary btn-active-primary m-2'
            >
              {intl.formatMessage({id: 'CONFIRM_LEAVE.CONTINUE'})}
            </Button>
          </div>
        </div>
      </div>

    </Modal>
  );
};
