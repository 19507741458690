import {useAuth} from '../modules/auth'

export const useGoBack = () => {
  const {auth} = useAuth()
  const hasAnyHistory = window.history.length > 2
  const goBackFn = () => {
    return auth?.isAdmin ? '/validate-orders' : '/my-orders'
  }
  const goBackHistoryFn = (e) => {
    e.preventDefault()
    window.history.back()
    if (localStorage.getItem('lastOrderView')) {
      setTimeout(() => {
        if (
          localStorage.getItem('lastOrderView') &&
          localStorage.getItem('lastOrderView')!.length > 0
        ) {
          const lastRowView = document.getElementById(
            `tableOrderRow${localStorage.getItem('lastOrderView')}`
          )
          if (lastRowView) {
            lastRowView.scrollIntoView({behavior: 'smooth'})
            lastRowView.classList.add('last-view')
          }
        }
      }, 1600)
    }
  }
  return {goBackFn, goBackHistoryFn, hasAnyHistory}
}
