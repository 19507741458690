import { useRef, useState } from 'react';
import Select from 'react-select';
import ErrorCatcher from './ErrorCatcher'

export default function SuperSelect({options, placeholder, onChange, selectedOptions, isMulti = false, required = false, forceValueByLocalStorageName = 'forcedValue' }) {
  const [selectedOption, setSelectedOption] = useState(selectedOptions);
  const refSelect = useRef()

  const getForcedValue = () => {
    const valueToForce = localStorage.getItem(forceValueByLocalStorageName)
    localStorage.removeItem(forceValueByLocalStorageName)
    if (valueToForce !== "-1" && options && Array.isArray(options)) {
      const byValueFound = options.find(x => x.value === valueToForce)
      if (byValueFound) {
        return byValueFound
      }
      // If no found by value (like status filter, try find by label or value depending on the length of value)
      return valueToForce && options && valueToForce.length > 1 ? options.find(x => x.label === valueToForce) : options.find(x => x.value === valueToForce)
    }
  }

  if (options && options.length < 0) return <></>;
  return (
    <>
      <ErrorCatcher>
        <Select
            
            ref={refSelect}
            value={localStorage.getItem(forceValueByLocalStorageName) ? getForcedValue() : undefined}
            defaultValue={selectedOption}
            onChange={ (selectedOption) => { setSelectedOption(); onChange(selectedOption, refSelect); } }
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            required={required}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: '200px',
                maxWidth: '100%'
              }),
            }}
            className='superselect'
        />
      </ErrorCatcher>
    </>
  );
}