import {FC, useEffect, useState} from 'react'
import {Field, useFormikContext} from 'formik'
import {useQuery, useQueryClient} from 'react-query'
import {Row, Spinner} from 'react-bootstrap'
import {Client, ShippingAddress} from '../../../../models/models'
import {customerAddress} from '../../../../requests/requests'
import {useIntl} from 'react-intl'
import {useProductsAvailablesForSelectedClient} from '../../../../hooks/useProductsAvailablesForSelectedClient'
import {ICreateOrder} from '../CreateOrder'
import { configStaleTime } from '../../../../constants'
import { useSelectedExpeditionDate } from '../../../../hooks/useSelectedExpeditionDate'
import { RefreshBtn } from '../../../../components/refreshBtn'

const StepOrder2: FC = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const formikContext = useFormikContext()
  const [selectedAddressInput, setSelectedAddressInput] = useState({} as ShippingAddress)
  const selectedClientID = localStorage.getItem('selectedClientID') as string
  const selectedExpeditionDate = useSelectedExpeditionDate();
  const {refetch: refetchClients, isFetching: isFetchingUser, isError} = useQuery<Client[]>(
    ['selectedUser'],
    async () => {
      const {data: x} = await customerAddress(selectedClientID)
      return x
    },
    {staleTime: configStaleTime, enabled: !!selectedClientID, retry: 3}
  )
  const selectedUser: Client = queryClient.getQueryData(['selectedUser']) as Client
  const {refetchAvailableProducts} = useProductsAvailablesForSelectedClient(selectedExpeditionDate)

  useEffect(() => {
    if (selectedClientID) {
      refetchClients()
    }
  }, [selectedClientID, refetchClients])

  const handleSelectedAddress = (address: ShippingAddress) => {
    localStorage.setItem('selectedAddress_SageCode', address.sageCode)
    queryClient.setQueryData(['selectedAddress'], address)
    setTimeout(() => {
      setSelectedAddressInput(address)
    }, 100)
    refetchAvailableProducts()
  }

  const isMainAddress = (shippingAddr: ShippingAddress): boolean => {
    const mainAddress = selectedUser?.shippingAddresses.find((x) => x.mainAddress)
    if (mainAddress) {
      return shippingAddr.sageCode === mainAddress.sageCode
    }
    return false
  }

  const isCheckedAddress = (shippingAddr: ShippingAddress) => {
    const inputCheckAddress = document.getElementById('address-' + shippingAddr.sageCode)
    const selectedAddr =
      localStorage.getItem('selectedAddress_SageCode') === shippingAddr.sageCode &&
      inputCheckAddress != null &&
      inputCheckAddress['checked']
    return selectedAddr
  }

  return (
    <section className='card card-flush p-8 flex-row-fluid overflow-hidden'>
      <Row>
        <h2 className='text-dark mb-8'>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_TITLE_ADRESS'})}
        </h2>
      </Row>

      {/* ADRESSES */}
      <Row className='mb-4' data-kt-buttons='true'>
        <h3 className='form-label fw-normal' style={{color: '#99A1B7'}}>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_ADRESSES'})}
        </h3>
      </Row>

      {isFetchingUser && <Spinner animation='border'></Spinner>}
      {isError && <RefreshBtn onClick={refetchClients} label={intl.formatMessage({id: 'BUTTON.REFRESH'})}/>}
      <ul className='d-flex justify-content-center list-unstyled flex-wrap mb-12'>
        {
          <Row className='w-100'>
            {selectedUser &&
              selectedUser?.shippingAddresses?.map((address: ShippingAddress) => (
                <li
                  className='col-12 col-md-6 col-lg-4 col-xl-3 mb-4 position-relative'
                  key={address.sageCode}
                >
                  <Field
                    type='radio'
                    // className='btn-check'
                    className='position-absolute top-0'
                    style={{transform: 'translate(16px, 18px)', accentColor: '#0c2340'}}
                    name='shippingAddress'
                    value={`${address.address} ${address.city} ${address.countryName} ${address.postalCode}-1`}
                    id={`address-${address.sageCode}`}
                    checked={
                      selectedAddressInput &&
                      selectedAddressInput.sageCode === address.sageCode &&
                      (formikContext.values as ICreateOrder)['shippingAddress']
                    }
                  />
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex h-100 px-13 ${
                      isCheckedAddress(address) ? 'active' : ''
                    } ${isMainAddress(address) ? 'bg-light-primary' : ''}`}
                    htmlFor={`address-${address.sageCode}`}
                    onClick={() => handleSelectedAddress(address)}
                  >
                    <ul className='d-flex flex-column align-items-start list-unstyled'>
                      <li className='text-start lh-sm fw-normal'>
                        {address.description} ({address.sageCode})
                      </li>
                      <li className='text-start lh-sm fw-normal'>{address.address}</li>
                      <li className='text-start lh-sm fw-normal'>
                        {`${address.postalCode} · ${address.city}`}
                      </li>
                      <li className='text-start lh-sm fw-normal'>{address.countryName}</li>
                    </ul>
                  </label>
                </li>
              ))}
          </Row>
        }
      </ul>
    </section>
  )
}

export {StepOrder2}
