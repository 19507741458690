import {Field, Formik, Form, FormikValues, FormikProps} from 'formik'
import {useEffect, useRef} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../_metronic/helpers'
import {GetFilterForcedValue} from '../helpers/filterForcedValue'

export const FilterByExpeditionDate = ({onSubmit, filteredDate, setFilteredDate}) => {
  const intl = useIntl()
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const today = new Date()
  useEffect(() => {
    if (formikRef && formikRef.current && filteredDate) {
      if (filteredDate instanceof Date && !isNaN(filteredDate.getTime())) {
        formikRef.current.setFieldValue('selectedDate', filteredDate.toISOString().split('T')[0])
      } else {
        formikRef.current.setFieldValue('selectedDate', '')
      }
    }
  }, [filteredDate])
  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{
        selectedDate: GetFilterForcedValue('forcedValueExpeditionDate')
          ? GetFilterForcedValue('forcedValueExpeditionDate')
          : filteredDate
          ? today.toISOString().split('T')[0]
          : '',
      }}
      onSubmit={async (values) => {
        const d = new Date(values.selectedDate)
        if (d instanceof Date && !isNaN(d.getTime())) {
          setFilteredDate(new Date(values.selectedDate))
        } else {
          setFilteredDate('')
        }
        onSubmit(values)
      }}
    >
      {() => (
        <Form placeholder={undefined}>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-end'>
              <div className='me-3 mb-3 mb-lg-0'>
                <KTIcon iconName='calendar' className='fs-3' />
                <span className='ms-3'>
                  {intl.formatMessage({id: 'GENERAL_FILTER_BY_EXPEDITION_DATE'})}
                </span>
              </div>
            </Col>
            <Col xs='12' lg='auto' className='mb-3 mb-lg-0'>
              <Field type='date' name='selectedDate' className='form-control w-100 w-lg-auto' />
            </Col>
            <Col xs='12' lg='auto'>
              <Button type='submit' className='d-inline-block w-100 w-lg-auto'>
                {intl.formatMessage({id: 'VALIDATE_ORDERS.FILTER'})}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
