import { useState } from 'react'
import { ProductExtension, ProductExtensionSingle } from '../../models/productExtensions'
import { useQuery } from 'react-query'
import { deleteProductExtension, requestProductExtension } from '../../requests/requests'
import { useIntl } from 'react-intl'
import { EditProductExtensionModal } from '../../components/edit-product-extension/editProductExtensionModal'
import { ConfirmDeleteModal } from '../../components/generic-confirm/Confirm'
import { KTIcon } from '../../../_metronic/helpers'

function SingleProductExtension({
  productExtension,
  refetch,
}: {
  productExtension: ProductExtension
  refetch: any
}) {
  const intl = useIntl()
  const [editShow, setEditShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const { refetch: refetchProductExtension } = useQuery<ProductExtensionSingle>(
    [`singleProductExtension${productExtension.id}`],
    () => {
      return requestProductExtension(productExtension.id).then(({ data: x }) => {
        return x
      })
    },
    { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5 }
  )

  const handleConfirmDelete = () => {
    deleteProductExtension(productExtension.id).then(() => {
      refetch()
    })
  }

  return (
    <tr>
      <td>
        <EditProductExtensionModal
          productExtensionId={productExtension.id}
          show={editShow}
          onHide={() => setEditShow(false)}
          refetchProducts={refetch}
          refetchProductSingle={refetchProductExtension}
        />
        <ConfirmDeleteModal
          show={deleteShow}
          onHide={() => setDeleteShow(false)}
          onConfirm={handleConfirmDelete}
          title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
          message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DELETE' })}
        />
        <div className='d-flex align-items-center'>
          {/* begin::Thumbnail*/}
          <span
            className='symbol-label symbol symbol-50px'
            style={{
              backgroundColor: '#f2f2f2',
              backgroundImage: `url(${productExtension.urlImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80px',
              aspectRatio: 1 / 1,
            }}
          ></span>
          {/* end::Thumbnail*/}
          {/* begin::Title*/}
          <div className='ms-5'>
            <h5 className='fw-bold'>{productExtension.productNameAdmin}</h5>
            {productExtension.inPromotion && productExtension.promotionalText.length > 0 && (
              <span className='badge badge-secondary'>{productExtension.promotionalText}</span>
            )}
          </div>
          {/* end::Title*/}
        </div>
      </td>
      {/* <td className='text-center'>
        {productExtension.disableWithCondition ? <KTIcon iconName='check-square' iconType='solid' className='text-success'/>: <KTIcon iconName='cross-square' iconType='solid' className='text-danger'/> }
      </td> */}
      <td>
        {productExtension.categories.map((x) => (
          <span className='badge badge-secondary me-3'>{x.name}</span>
        ))}
      </td>
      <td>
        {/* begin::SageCode*/}
        <div className='fw-bold text-gray-600'>{productExtension.sageCode}</div>
        {/* end::SageCode*/}
      </td>
      <td>
        <div className='text-end d-flex justify-content-end gap-4'>
          <span className='text-decoration-underline fw-semibold' onClick={() => setEditShow(true)}>
            {intl.formatMessage({ id: 'BUTTON.EDIT' })}
          </span>
          <span
            className='text-decoration-underline fw-semibold'
            onClick={() => setDeleteShow(true)}
          >
            {intl.formatMessage({ id: 'BUTTON.DELETE' })}
          </span>
        </div>
      </td>
    </tr>
  )
}

export default SingleProductExtension
