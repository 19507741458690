import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues} from 'formik'
import {useQuery, useQueryClient} from 'react-query'
import {useShoppingCart} from '../../../context/ShoppingCartContext'
import {
  CartItem,
  CartPrice,
  Client,
  CartLine,
  ShippingAddress,
  CartPriceResponse,
  OrderWithDetail,
  OrderDetail,
  OrderLine,
} from '../../../models/models'
import {useIntl} from 'react-intl'
import {requestCartPrice, requestOrderSend} from '../../../requests/requests'
import {ToastifyCaller, ToastifyType} from '../../../components/Toastify'
import {useNavigateHelper} from '../../../hooks/useNavigateHelper'
import {Button, Col, Row} from 'react-bootstrap'
import {OrderStepsForm} from './order-steps-form'
import {ICreateOrder, createOrderSchema} from './CreateOrder'
import {formatDate} from '../../../helpers/formatDate'

const HorizontalOrders = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [currentSchema, setCurrentSchema] = useState(createOrderSchema[0])
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const {cartItems, clearCart, cartPricing, setCartPricing, setIsAddingToCart} = useShoppingCart()
  const needGetPricing = stepper.current?.currentStepIndex === 5
  const [sendingOrder, setSendingOrder] = useState<boolean>(false)

  const {refetch: refetchPricing} = useQuery<CartPriceResponse>(
    ['availablePrices'],
    () => {
      if (cartItems.length <= 0) return {} as CartPriceResponse
      if (cartPricing) {
        setCartPricing(false)
      }
      if (!selectedUser) return {} as CartPriceResponse
      if (!cartItems) return {} as CartPriceResponse
      const cartPrice = {} as CartPrice
      cartPrice.clientId = (selectedUser as Client).id
      cartPrice.expeditionDate = selectedDate
      cartPrice.shippingAddressSageCode = (selectedAddress as ShippingAddress).sageCode
      cartPrice.lines = [] as CartLine[]
      cartItems.forEach((cartItem: CartItem) => {
        cartPrice.lines.push({
          code: cartItem.code,
          packageUnit: cartItem.packageUnit,
          stockRatio: cartItem.stockRatio,
          quantity: cartItem.quantity,
          price: cartItem.netPricePerUnit,
          splitPieceQuestion: cartItem.splitPieceQuestion,
          completeOrder: cartItem.completeOrder,
        } as CartLine)
      })
      return requestCartPrice(cartPrice).then(({data: x}) => {
        // Add a extra attribute to response of api model
        cartItems.forEach((cartItem: CartItem) => {
          const itemIndex = x.lines.findIndex(
            (x) =>
              x.code === cartItem.code &&
              x.packageUnit === cartItem.packageUnit &&
              x.stockRatio === cartItem.stockRatio
          )
          if (itemIndex !== -1) {
            x.lines[itemIndex].splitPieceQuestion = cartItem.splitPieceQuestion
            x.lines[itemIndex].completeOrder = cartItem.completeOrder
          }
        })
        setCartPricing(true)
        setIsAddingToCart('')
        return x
      })
    },
    {
      staleTime: 60 * 60 * 24 * 365,
      enabled: !!needGetPricing,
      /*onError: (error: any) => {
        ToastifyCaller(`${error.message}`, ToastifyType.ERROR)
      },*/
    }
  )
  const cartPriceResponse: CartPriceResponse = queryClient.getQueryData([
    'availablePrices',
  ]) as CartPriceResponse

  // client info
  const selectedUser: Client = queryClient.getQueryData('selectedUser') as Client
  const selectedAddress: ShippingAddress = queryClient.getQueryData(
    'selectedAddress'
  ) as ShippingAddress
  const selectedDate: string = queryClient.getQueryData('selectedDate') as string
  const customerExternalReference: string = queryClient.getQueryData('customerExternalReference') as string
  const navigateHelper = useNavigateHelper()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createOrderSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateOrder, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      if (
        stepper.current.currentStepIndex < stepper.current.totalStepsNumber - 1 ||
        cartItems.length > 0
      ) {
        stepper.current.goNext()
      }
    } else {
      if (cartPriceResponse) {
        const orderObj: OrderWithDetail = {
          clientId: cartPriceResponse.clientId,
          expeditionDate: selectedDate,
          detail: {
            shippingAddressSageCode: (selectedAddress as ShippingAddress).sageCode,
            customerExternalReference: customerExternalReference
          } as OrderDetail,
          lines: cartPriceResponse.lines as OrderLine[],
        } as OrderWithDetail

        if (cartItems.length <= 0) {
          ToastifyCaller(
            intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_EMPTY'}),
            ToastifyType.ERROR
          )
        }
        if (cartPricing && cartItems.length > 0) {
          setSendingOrder(true)
          requestOrderSend(orderObj).then(() => {
            setSendingOrder(false)
            stepper.current?.goto(1)
            localStorage.setItem('orderSended', 'true')
            resetOrder()
          })
        }
      }
    }
    setCurrentSchema(createOrderSchema[stepper.current.currentStepIndex - 1])
  }

  const resetOrder = () => {
    if (stepper.current?.currentStepIndex) {
      // erase adress & date queries
      queryClient.removeQueries('selectedUser', {exact: true})
      queryClient.removeQueries('selectedAddress', {exact: true})
      queryClient.removeQueries('selectedDate', {exact: true})
      localStorage.removeItem('selectedClientID')
      localStorage.removeItem('selectedExpeditionDate')
      navigateHelper('/dashboard')
      clearCart()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (stepper.current?.currentStepIndex === 1 && stepper.current?.passedStepIndex <= 1) {
      // Solo ocurrira cuando te dejes a medias el proceso
      if (cartItems && cartItems.length > 0 && selectedAddress) {
        const stepToGo = 4
        setCurrentSchema(createOrderSchema[stepToGo])
        stepper.current?.goto(stepToGo)
      }
    }
  }, [cartItems, selectedAddress, stepper.current?.currentStepIndex])

  useEffect(() => {
    if (stepper.current?.currentStepIndex === 5) {
      refetchPricing()
    }
  }, [refetchPricing, stepper.current?.currentStepIndex])

  const handleRealSubmit = () => {
    // Manual force validation for the 'second' submit btn
    document.getElementById('newOrderSubmit')?.click()
    document.getElementById('newOrderSubmitFinal')?.click()
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-row-fluid'
      id='kt_create_account_stepper_create_orders'
    >
      {/* begin::Stepper*/}
      <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9 mt-4'>
        {/* begin::Wrapper*/}
        <div className='card-body p-lg-10'>
          <Row>
            <div className='d-lg-flex justify-content-between align-items-center mb-8'>
              <Col xs='12' lg='6' className='mb-5 mb-lg-0'>
                <h1 className='mb-0'>
                  {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_TITLE'})}
                </h1>
              </Col>
              <Col xs='12' lg='6'>
                <div className='d-flex gap-4 justify-content-end'>
                  <Row className='justify-content-between align-items-center w-100 w-lg-auto g-0'>
                    <Col xs='auto' lg='auto'>
                      {stepper?.current?.currentStepIndex !== undefined &&
                        stepper?.current?.currentStepIndex > 0 && (
                          <Button
                            variant='secondary'
                            className='fs-5 btn-lg me-lg-3'
                            onClick={resetOrder}
                          >
                            {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                          </Button>
                        )}
                    </Col>
                    <Col xs='auto' lg='auto'>
                      <Button
                        onClick={prevStep}
                        type='button'
                        variant='light-primary'
                        className='btn-lg me-lg-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTIcon iconName='arrow-left' className='fs-3 me-1' />
                        <span className='d-none d-lg-inline-block'>
                          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_BACK'})}
                        </span>
                      </Button>
                    </Col>
                    <Col xs='auto' lg='auto'>
                      {stepper?.current?.totalStepsNumber! > 0 &&
                      stepper?.current?.currentStepIndex === stepper?.current?.totalStepsNumber ? (
                        <Button
                          type='submit'
                          className={`btn btn-lg btn-primary ${sendingOrder ? 'disabled' : ''}`}
                          onClick={handleRealSubmit}
                        >
                          <span className='indicator-label d-flex align-items-center'>
                            <span className='d-none d-lg-inline-block'>
                              {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_END'})}
                            </span>
                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                            {sendingOrder && (
                              <span className='spinner-border h-15px w-15px align-middle text-gray-400 mx-3' />
                            )}
                          </span>
                        </Button>
                      ) : (
                        <Button
                          type='submit'
                          className={`btn btn-lg btn-primary ${sendingOrder ? 'disabled' : ''}`}
                          onClick={handleRealSubmit}
                          data-kt-stepper-action='next'
                        >
                          <span className='indicator-label d-flex align-items-center'>
                            <span className='d-none d-lg-inline-block'>
                              {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_NEXT'})}
                            </span>
                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                            {sendingOrder && (
                              <span className='spinner-border h-15px w-15px align-middle text-gray-400 mx-3' />
                            )}
                          </span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </div>
          </Row>
          {/* begin::Nav*/}
          <div className='stepper-nav flex-row justify-content-between gap-4'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label d-lg-block d-none'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER1_TITLE'})}
                  </h3>
                  <div className='stepper-desc fw-semibold'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER1_SUBTITLE'})}
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label d-lg-block d-none'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER2_TITLE'})}
                  </h3>
                  <div className='stepper-desc fw-semibold'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER2_SUBTITLE'})}
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              {/* <div className='stepper-line h-40px'></div> */}
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label d-lg-block d-none'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER3_TITLE'})}
                  </h3>
                  <div className='stepper-desc fw-semibold'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER3_SUBTITLE'})}
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label d-lg-block d-none'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER4_TITLE'})}
                  </h3>
                  <div className='stepper-desc fw-semibold'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER4_SUBTITLE'})}
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label d-lg-block d-none'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_TITLE'})}
                  </h3>
                  <div className='stepper-desc fw-semibold'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_SUBTITLE'})}
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 4*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* end::Stepper*/}

      {/* begin::Body*/}
      <div className='d-flex flex-column flex-row-fluid rounded mt-5 mt-lg-0'>
        {
          // This section only show up when a user is selected
          selectedUser && (
            <section className='d-flex flex-column align-content-start py-10 p-lg-10'>
              <h3 className='mb-10 fs-2 fw-bold'>
                {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_TITLE'})}
              </h3>

              <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
                {/* Client card */}
                {selectedUser && (
                  <div className='card card-flush py-4 flex-row-fluid position-relative'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-two-credit-cart' style={{fontSize: '14em'}}></i>{' '}
                    </div>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h4 className='fs-2'>
                          {intl.formatMessage({
                            id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_CUSTOMER_DATA',
                          })}
                        </h4>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <span className='fs-4 mb-0'>{selectedUser?.businessName}</span>
                      <br />{' '}
                    </div>
                  </div>
                )}
                {/* Adress card */}
                {selectedAddress && (
                  <div className='card card-flush py-4 flex-row-fluid position-relative'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-delivery' style={{fontSize: '14em'}}></i>
                    </div>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h4 className='fs-2'>
                          {intl.formatMessage({
                            id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_ADDRESS',
                          })}
                        </h4>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      {selectedAddress.description} ({selectedAddress?.sageCode})
                      <br />
                      {selectedAddress?.address}
                      <br />
                      {selectedAddress?.postalCode} - {selectedAddress?.city}
                      <br />
                      {selectedAddress?.countryName} <br />
                      <br />{' '}
                    </div>
                  </div>
                )}
                {/* Date card */}
                {selectedDate && (
                  <div className='card card-flush py-4 flex-row-fluid position-relative'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-calendar' style={{fontSize: '14em'}}></i>
                    </div>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h4 className='fs-2'>
                          {intl.formatMessage({
                            id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_DATE',
                          })}
                        </h4>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      {formatDate(selectedDate)}
                      <br />
                    </div>
                  </div>
                )}
              </div>
            </section>
          )
        }

        <OrderStepsForm
          currentSchema={currentSchema}
          submitStep={submitStep}
          prevStep={prevStep}
          stepper={stepper}
          sendingOrder={sendingOrder}
        ></OrderStepsForm>
      </div>
      {/* end::Body*/}
    </div>
  )
}

export {HorizontalOrders}
