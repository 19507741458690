import { useFormikContext } from 'formik';
import { useState, ChangeEvent } from 'react';

function FileInput() {
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const { setFieldValue } = useFormikContext();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        setImageBase64((event?.target?.result) as string);
        console.log((event?.target?.result) as string)
        setFieldValue('imageBs64', event?.target?.result.split(',')[1] as string);
        console.log(event?.target?.result.split(',')[1] as string)
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='d-flex flex-column'>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        name='imageBs64' 
        className='custom__file-input'      
      />      
      {imageBase64 && (
        <img
          src={imageBase64}
          alt="Preview"
          style={{maxWidth: '150px', aspectRatio: 16/9, objectFit: 'cover'}}
        />
      )}
    </div>
  );
}

export default FileInput;

