import {Modal, Button, Form} from 'react-bootstrap'
import {Formik, Field} from 'formik'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {addProductExtension, requestCategories} from '../../requests/requests'
import {
  ProductExtensionAdd,
  SuperSelectOption,
  TranslatableInput,
  TranslatableValue,
} from '../../models/productExtensions'
import {Language} from '../../models/models'
import SuperSelect from '../SuperSelect'
import FileInput from '../../helpers/imgToBase64'

export function AddProductExtensionModal({show, onHide, refetch}) {
  const intl = useIntl()

  const {data: categoriesData} = useQuery('categories', () => requestCategories(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  const addInputs = () => {
    let arr: TranslatableInput[] = []
    arr.push({
      name: `productNameAdmin`,
      type: 'text',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_NAME_ADMIN',
      required: false,
      initialValue: '',
      translatable: false,
      originalName: `productNameAdmin`,
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_PRODUCT_NAME_ADMIN'}),
    })
    arr.push({
      name: `sageCode`,
      type: 'text',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_SAGE_CODE',
      required: true,
      initialValue: '',
      translatable: false,
      originalName: `sageCode`,
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_SAGE_CODE'}),
    })
    arr.push({
      name: `imageBs64`,
      type: 'customfile',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_IMAGE',
      required: false,
      initialValue: '',
      translatable: false,
      originalName: `imageBs64`,
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_IMAGE'}),
    })
    arr.push({
      name: `categories`,
      type: 'superselect',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_CATEGORIES',
      required: false,
      initialValue: [] as number[],
      translatable: false,
      originalName: `categories`,
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_CATEGORIES'}),
    })
    arr.push({
      name: `inPromotion`,
      type: 'checkbox',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_IN_PROMOTION',
      required: false,
      initialValue: false,
      translatable: false,
      originalName: `inPromotion`,
      className: 'd-flex justify-content-start',
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_IN_PROMOTION'}),
    })
    /*arr.push({
      name: `disableWithCondition`,
      type: 'checkbox',
      labelTranslationKey: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_DISABLE_WITH_CONDITIONAL',
      required: false,
      initialValue: false,
      translatable: false,
      originalName: `disableWithCondition`,
      className: 'd-flex justify-content-start',
      helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_DISABLE_WITH_CONDITIONAL'}),
    })*/
    for (let lang in Language) {
      if (!isNaN(Number(lang))) {
        arr.push({
          name: `promotionalText${lang}`,
          type: 'text',
          lang: Number(lang),
          labelTranslationKey: 'PRODUCT_EXTENSIONS.ADD_MODAL_PRODUCT_PROMOTIONAL_TEXT',
          required: false,
          initialValue: '',
          translatable: true,
          originalName: 'promotionalText',
          helperText: intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_PROMOTIONAL_TEXT'}),
        })
      }
    }
    return arr
  }

  const initialValues = () => {
    let initial = { disableWithCondition: false }
    addInputs().forEach((el) => {
      initial[el.name] = el.initialValue
    })
    initial[`allCategories`] = categoriesData?.data.map(
      (cat) => ({value: cat.id, label: cat.name} as SuperSelectOption)
    )
    return initial
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className='text-black'>
            {intl.formatMessage({id: 'PRODUCT_EXTENSIONS.ADD_MODAL_TITLE'})}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={initialValues()}
          onSubmit={(values) => {
            const addProductExtensionObj = {} as ProductExtensionAdd
            Object.entries(values).forEach((el) => {
              const valueInput: string = el[1] as unknown as string
              const transInput: TranslatableInput = addInputs().find(
                (x) => x.name === el[0]
              ) as TranslatableInput
              if (transInput !== undefined) {
                let arr: TranslatableValue[] =
                  addProductExtensionObj[`${transInput.originalName as string}`]
                if (arr === undefined) {
                  addProductExtensionObj[`${transInput.originalName as string}`] =
                    [] as TranslatableValue[]
                }
                if (transInput.translatable) {
                  const transValue: TranslatableValue = {
                    value: valueInput,
                    language: transInput.lang,
                  } as TranslatableValue
                  addProductExtensionObj[`${transInput.originalName as string}`].push(transValue) // translatable value
                } else {
                  addProductExtensionObj[transInput.name] = valueInput // no translatable value
                }
              }
            })
            addProductExtension(addProductExtensionObj).then(() => {
              refetch()
              onHide()
            })
          }}
        >
          {({handleSubmit, values}) => (
            <Form onSubmit={handleSubmit}>
              {addInputs().map((el) => (
                <div key={el.name} className='mb-8'>
                  <Form.Group
                    className={
                      (el.name === 'inPromotion' || el.name === 'disableWithCondition') ? 'd-flex align-items-center' : 'd-flex flex-column'
                    }
                  >
                    {el.name !== 'inPromotion' && el.name !== 'disableWithCondition' && (
                      <>
                        <Form.Label className='fs-4 text-black' htmlFor={el.name}>
                          {intl.formatMessage({id: el.labelTranslationKey})}&nbsp;
                          {el.lang !== undefined && el.lang === Language.English
                            ? intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_EN'})
                            : ''}
                          {el.lang !== undefined && el.lang === Language.Spanish
                            ? intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_ES'})
                            : ''}
                        </Form.Label>
                      </>
                    )}

                    {el.type === 'superselect' && (
                      <SuperSelect
                        options={values['allCategories']}
                        placeholder={''}
                        onChange={(optionElement) => {
                          values[el.name] = optionElement.map((x) => x.value)
                        }}
                        isMulti={true}
                        selectedOptions={false}
                        required={el.required}
                      ></SuperSelect>
                    )}
                    {el.type === 'customfile' && <FileInput />}
                    {el.type !== 'customfile' && el.type !== 'superselect' && (
                      <Field
                        type={el.type}
                        name={el.name}
                        className={
                          el.className ? el.className : 'form-control bg-light border-0 text-black'
                        }
                        required={el.required}
                        style={el.name === 'inPromotion' || el.name === 'disableWithCondition' ? {accentColor: '#071426'} : {}}
                        id={el.name}
                        maxLength={el.name.startsWith('promotionalText') ? 15 : 100}
                      />
                    )}

                    {(el.name === 'inPromotion' || el.name === 'disableWithCondition') && (
                      <>
                        <Form.Label
                          className='mb-0 mx-2 fs-4 text-black w-100 form-label'
                          htmlFor={el.name}
                        >
                          {intl.formatMessage({id: el.labelTranslationKey})}&nbsp;
                          {el.lang !== undefined && el.lang === Language.English
                            ? intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_EN'})
                            : ''}
                          {el.lang !== undefined && el.lang === Language.Spanish
                            ? intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_ES'})
                            : ''}
                        </Form.Label>
                      </>
                    )}

                    {el.name !== 'inPromotion' && el.name !== 'disableWithCondition' && el.helperText && (
                      <span className='text-gray-400 mt-2'>{el.helperText}</span>
                    )}
                  </Form.Group>
                  {(el.name === 'inPromotion' || el.name === 'disableWithCondition') && el.helperText && (
                    <span className='text-gray-400 mt-2'>{el.helperText}</span>
                  )}
                </div>
              ))}
              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({id: 'BUTTON.ADD'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
