/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {OrderStatus} from '../../models/models'
import {MyOrder} from '../../models/myOrders'
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {Field, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {formatPrice} from '../../helpers/formatPrice'
import {formatDate, inputDateFormat} from '../../helpers/formatDate'
import {ConfirmDeleteModal} from '../../components/generic-confirm/ConfirmDelete'
import {useBtnTitles} from '../../hooks/useBtnTitles'
import {usePaginationForWrapper} from '../../hooks/usePaginationForWrapper'
import {PaginationWrapper} from '../../components/PaginationWrapper'
import SuperSelect from '../../components/SuperSelect'
import {useGetMyClients} from '../../hooks/useGetMyClients'
import {FilterByStatus} from '../../components/FilterByStatus'
import {ToNormalizedState} from '../../helpers/utils'
import {useSaveFilters} from '../../hooks/useSaveFilters'
import {RemoveFilterForcedValue, SaveFilterForcedValue} from '../../helpers/filterForcedValue'

type Props = {
  className: string
  headerTitle: string
  headerDescription: string
  orders: MyOrder[]
  headers: string[]
  setUsingFilter
  filterDate
  setFilterDate
  handleRemove
  isFetching
}

const MyOrdersTable: React.FC<Props> = ({
  className,
  headerTitle,
  headerDescription,
  orders,
  headers,
  filterDate,
  setUsingFilter,
  setFilterDate,
  handleRemove,
  isFetching,
}) => {
  const intl = useIntl()
  const clearFilter = () => {
    setFilterDate('')
    setUsingFilter(false)
    removeSavedFilter('filteredExpeditionDate')
  }
  const [recoveredFilter, setRecoveredFilter] = useState<boolean>(false)
  const [filteredUser, setFilteredUser] = useState('')
  const [filteredUserLabel, setFilteredUserLabel] = useState('')
  const [filteredStatus, setFilteredStatus] = useState<Number | string>(-1)
  const btnTitles = useBtnTitles()
  const {saveFilter, removeSavedFilter, existSavedFilter, getSavedFilter} = useSaveFilters()
  const {myClientsOptions} = useGetMyClients()
  const handleChangeSelectedClient = (selected) => {
    setFilteredUser(selected.value)
    setFilteredUserLabel(selected.label)
    saveFilter('filteredUser', selected.value)
  }

  // Get a state for filters
  const orderGroupByState = orders.filter((value, index, array) => {
    return (
      array.findIndex((x) => x.sageOrderStatus === value.sageOrderStatus) === index &&
      value.sageOrderStatus != null
    )
  })
  const orderStates = orderGroupByState.map((x) => ({
    value: x.sageOrderStatus,
    label: x.sageOrderStatus,
  }))

  if (filteredUser) {
    orders = orders.filter((x) => x.clientId === filteredUser)
    SaveFilterForcedValue(filteredUser, 'forcedValueClient')
  }
  if (filteredStatus) {
    if (typeof filteredStatus == 'string') {
      const parsedInt = parseInt(filteredStatus)
      if (isNaN(parsedInt)) {
        orders = orders.filter(
          (x) =>
            ToNormalizedState(x.sageOrderStatus as string) === ToNormalizedState(filteredStatus)
        )
      } else {
        if (parsedInt >= 0) {
          orders = orders.filter((x) => x.status === parsedInt)
        }
      }
    }
    SaveFilterForcedValue(filteredStatus, 'forcedValueStatus')
  }
  const paginationObj = usePaginationForWrapper(orders, 8)
  useEffect(() => {
    paginationObj.refreshPagination()
  }, [paginationObj])
  useEffect(() => {
    if (!recoveredFilter && myClientsOptions() && Object.entries(myClientsOptions()).length > 0) {
      // allUsers
      setRecoveredFilter(true)
      if (existSavedFilter('filteredUser')) {
        const filtered = getSavedFilter('filteredUser')
        const clients = myClientsOptions() as []
        const filteredClient = clients.find(
          (x: {value: string; label: string}) => x.value === filtered
        )
        if (filteredClient) {
          setFilteredUser(filtered)
          setFilteredUserLabel(filteredClient['label'])
        }
      }
      if (existSavedFilter('filteredStatus')) {
        const filtered = getSavedFilter('filteredStatus')
        setFilteredStatus(filtered)
      }
      if (existSavedFilter('filteredExpeditionDate')) {
        const filtered = new Date(getSavedFilter('filteredExpeditionDate'))
        setFilterDate(filtered)
      }
    }
  }, [
    existSavedFilter,
    getSavedFilter,
    myClientsOptions,
    recoveredFilter,
    setFilterDate,
    setFilteredUser,
  ])

  const hasExpeditionDateFiltered = getSavedFilter('filteredExpeditionDate')

  return (
    <>
      <div className='d-flex justify-content-between mb-2'>
        <div>
          <h1>{headerTitle}</h1>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{headerDescription}</span>
        </div>
        <Link to='/orders'>
          <Button type='button' className='my-2'>
            + {intl.formatMessage({id: 'MY_ORDERS_NEW_ORDER'})}
          </Button>
        </Link>
      </div>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row className='w-100 justify-content-between align-items-center'>
            <Col lg='auto'>
              <FilterByStatus
                translator={intl}
                handleChangeSelected={(x) => {
                  saveFilter('filteredStatus', x.value)
                  if (typeof x.value === 'number') {
                    setFilteredStatus(parseInt(x.value))
                  } else if (typeof x.value === 'string') {
                    setFilteredStatus(x.value)
                  }
                }}
                sageStatusInFilter={orderStates}
              >
                {(typeof filteredStatus === 'number' && filteredStatus >= 0) ||
                  (typeof filteredStatus === 'string' && (
                    <Button
                      variant='primary'
                      type='button'
                      className='ms-3'
                      onClick={() => {
                        RemoveFilterForcedValue('forcedValueStatus')
                        setFilteredStatus(-1)
                        removeSavedFilter('filteredStatus')
                      }}
                    >
                      {intl.formatMessage({id: 'MY_ORDERS_FILTER_CLEAR'})}
                    </Button>
                  ))}
              </FilterByStatus>
            </Col>
            <Col lg='auto' className='mb-3 mb-lg-0'>
              <div>
                <div className='me-3 mb-3 d-inline-flex'>
                  <KTIcon iconName='user' className='fs-3' />
                  <span className='ms-3'>
                    {intl.formatMessage({id: 'ALL_ORDERS_WITH_FILTERS_FILTER_BY_CUSTOMER'})}
                  </span>
                </div>
                <div className='d-inline-flex'>
                  <SuperSelect
                    options={myClientsOptions()}
                    onChange={handleChangeSelectedClient}
                    placeholder={intl.formatMessage({id: 'GENERAL_SELECT_ANY_OPTIONS'})}
                    selectedOptions={null}
                    forceValueByLocalStorageName='forcedValueClient'
                  ></SuperSelect>
                  {filteredUser && (
                    <Button
                      variant='primary'
                      type='button'
                      className='ms-3 d-inline-block'
                      onClick={() => {
                        setFilteredUser('')
                        removeSavedFilter('filteredUser')
                      }}
                    >
                      {intl.formatMessage({id: 'MY_ORDERS_FILTER_CLEAR'})}
                    </Button>
                  )}
                </div>
              </div>
              <div className='mx-auto text-center'>
                {filteredUser && (
                  <span className='badge badge-dark mt-3'>
                    {intl.formatMessage({id: 'GENERAL_SHOWING_ORDERS_OF'})} {filteredUserLabel}
                  </span>
                )}
              </div>
            </Col>
            <Col lg='auto'>
              <Formik
                initialValues={{
                  FilterDate: hasExpeditionDateFiltered
                    ? inputDateFormat(hasExpeditionDateFiltered)
                    : '',
                }}
                onSubmit={(values) => {
                  if (values.FilterDate) {
                    setFilterDate(values.FilterDate)
                    setUsingFilter(true)
                    saveFilter('filteredExpeditionDate', new Date(values.FilterDate).getTime())
                  }
                }}
              >
                {({handleSubmit, values}) => (
                  <Form
                    className='row align-items-center justify-content-between'
                    onSubmit={handleSubmit}
                  >
                    <div className='col-auto'>
                      <InputGroup className='mb-3 mb-lg-0'>
                        <Field
                          type='date'
                          name='FilterDate'
                          aria-label='Filter by date'
                          className='form-control'
                          value={values.FilterDate}
                        ></Field>
                      </InputGroup>
                    </div>
                    <div className='col-auto'>
                      <Button type='submit' className='m-0'>
                        {intl.formatMessage({id: 'MY_ORDERS_FILTER'})}
                      </Button>

                      {hasExpeditionDateFiltered && (
                        <Button type='button' className='mb-5 ms-5 mb-lg-0' onClick={clearFilter}>
                          {intl.formatMessage({id: 'MY_ORDERS_FILTER_CLEAR'})}
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {isFetching && (
              <div>
                <i className='fa fa-spinner fs-2x fa-sync fa-spin'></i>
              </div>
            )}
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  {headers.map((el, i) =>
                    i > 1 ? (
                      <th className='min-w-150px text-end' key={el + i}>
                        {el.toUpperCase()}
                      </th>
                    ) : (
                      <th className='min-w-150px' key={el + i}>
                        {el.toUpperCase()}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {paginationObj.currentItems &&
                  paginationObj.currentItems.map((order: MyOrder) => (
                    <tr key={order.guidId}>
                      <td className='text-start'>{formatDate(order.dateCreated)}</td>
                      <td className='text-start'>
                        <Link to={'#'} className='text-dark text-hover-primary fs-6'>
                          {order.detail.clientBusinessName}
                        </Link>
                      </td>
                      <td className='text-end'>{order.detail.shippingAddressDescription}</td>
                      <td className='text-end'>{formatDate(order.expeditionDate)}</td>
                      <td className='text-end'>
                        <Link to={'#'} className='text-dark text-hover-primary fs-6'>
                          {formatPrice(order.finalAmount)}
                        </Link>
                      </td>
                      <td className='text-end'>
                        {order.status === OrderStatus.Pending && (
                          <span className={`badge badge-light-warning`}>
                            <span>
                              {intl.formatMessage({
                                id: 'ORDER.STATUS.' + OrderStatus[order.status].toUpperCase(),
                              })}
                            </span>
                          </span>
                        )}
                        {order.sageOrderStatus && order.status === OrderStatus.Validated && (
                          <span
                            className={`badge ${
                              order.lines && order.lines.find((x) => x.invoices.length > 0)
                                ? 'badge-light-success'
                                : 'badge-light-primary'
                            }`}
                          >
                            <span>{order.sageOrderStatus}</span>
                          </span>
                        )}
                      </td>
                      <td className='text-end'>
                        {order.sageOrder && (
                          <span className={`badge badge-light-primary`}>
                            <span>{order.sageOrder}</span>
                          </span>
                        )}
                      </td>
                      <td className='text-end'>
                        {order.detail.customerExternalReference && (
                          <span className={`badge badge-light-primary`}>
                            <span>{order.detail.customerExternalReference}</span>
                          </span>
                        )}
                      </td>
                      <td className='text-end'>
                        {order.status === OrderStatus.Pending && (
                          <Link
                            to={`/order-edit/${order.guidId}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title={btnTitles.edit}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                        )}
                        <Link
                          to={`/order-detail/${order.guidId}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          title={btnTitles.details}
                        >
                          <KTIcon iconName='eye' className='fs-3' />
                        </Link>
                        {order.status === OrderStatus.Pending && (
                          <>
                            <ConfirmDeleteModal
                              onConfirm={() => {
                                handleRemove(order.guidId)
                              }}
                              title={intl.formatMessage({id: 'CONFIRM_TEXT'})}
                              message={intl.formatMessage({id: 'BUTTON.CONFIRM_DELETE'})}
                            ></ConfirmDeleteModal>
                          </>
                        )}
                        {/* {ShowPackagingListOrInvoiceInOrderList &&
                          order.status === OrderStatus.Validated &&
                          order.documents &&
                          order.documents.length > 0 && (
                            <>
                              <a
                                className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ${
                                  generatingPDF ? 'disabled' : ''
                                }`}
                                title={btnTitles.invoice}
                                href='#invoice'
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await generateInvoicePDF(
                                    setGeneratingPDF,
                                    setGeneratingOrderPDF,
                                    order,
                                    ''
                                  )
                                }}
                              >
                                {generatingPDF === 1 && generatingOrderPDF === order.guidId && (
                                  <Spinner
                                    className='position-absolute'
                                    animation='border'
                                  ></Spinner>
                                )}
                                <KTIcon iconName='document' className='fs-3' />
                              </a>
                            </>
                          )} */}

                        {/* {ShowPackagingListOrInvoiceInOrderList &&
                          order.status === OrderStatus.Validated &&
                          order.deliveryNote &&
                          order.deliveryNote.length > 0 && (
                            <>
                              <a
                                className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ${
                                  generatingPDF ? 'disabled' : ''
                                }`}
                                title={btnTitles.packagingList}
                                href='#packaging-list'
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await generatePackagingListPDF(
                                    setGeneratingPDF,
                                    setGeneratingOrderPDF,
                                    order,
                                    ''
                                  )
                                }}
                              >
                                {generatingPDF === 1 && generatingOrderPDF === order.guidId && (
                                  <Spinner
                                    className='position-absolute'
                                    animation='border'
                                  ></Spinner>
                                )}
                                <KTIcon iconName='questionnaire-tablet' className='fs-3' />
                              </a>
                            </>
                          )} */}
                      </td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        <PaginationWrapper
          currentPage={paginationObj.currentPage}
          chunkedArray={paginationObj.chunkedArray}
          handleNextClick={paginationObj.handleNextClick}
          handlePaginationNumberClick={paginationObj.handlePaginationNumberClick}
          handlePreviousClick={paginationObj.handlePreviousClick}
          productSearch=''
          paginationWrapperClass={'my-5'}
        ></PaginationWrapper>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {MyOrdersTable}
