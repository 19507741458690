import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { requestProductExtensions } from '../../requests/requests'
import { ProductExtension } from '../../models/productExtensions'
import SingleProductExtension from './singleProductExtension'
import { useState } from 'react'
import { AddProductExtensionModal } from '../../components/add-product-extension/addProductExtensionModal'

export function ProductExtensions() {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  // get data
  const {
    isLoading,
    error,
    data,
    refetch: refetchProductExtension,
  } = useQuery('productExtensions', () => requestProductExtensions(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  return (
    <>
      <div className='d-flex justify-content-between mb-2'>
        <div>
          <h1>{intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.PRODUCT_EXTENSIONS_TITLE' })}</h1>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.PRODUCT_EXTENSIONS_SUBTITLE' })}
          </span>
        </div>
        <Button onClick={() => setShow(true)} className='my-2'>
          {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.ADD_MODAL_TITLE' })}
        </Button>
      </div>

      {/* Begin Product list */}
      <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
        {/* begin::Card body*/}
        <div className='card-body pt-0'>
          <div className='table-responsive'>
            <AddProductExtensionModal
              show={show}
              onHide={() => setShow(false)}
              refetch={refetchProductExtension}
            />
            {/* begin::Table*/}
            <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-175px'>
                    {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.LIST_HEADER_PRODUCT' })}
                  </th>
                  {/* <th className='min-w-70px text-center'>
                    {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.LIST_HEADER_DISABLE_WITH_CONDITIONAL' })}
                  </th> */}
                  <th className='min-w-70px'>
                    {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.LIST_HEADER_CATEGORIES' })}
                  </th>
                  <th className='min-w-70px'>
                    {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.LIST_HEADER_SAGECODE' })}
                  </th>
                  <th className='min-w-70px text-end'>
                    {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.LIST_HEADER_OPTIONS' })}
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {isLoading && (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.LOADING' })}</td>
                  </tr>
                )}
                {error ? (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.ERROR' })}</td>
                  </tr>
                ) : (
                  false
                )}
                {data?.data.map((prodExtension: ProductExtension) => (
                  <SingleProductExtension
                    productExtension={prodExtension}
                    key={prodExtension.id}
                    refetch={refetchProductExtension}
                  />
                ))}
              </tbody>
            </table>
            {/* end::Table*/}
          </div>
        </div>
        {/* end::Card body*/}
      </div>
    </>
  )
}
