import {useQuery} from 'react-query'
import {requestAllDistributors, requestProfile} from '../../requests/requests'
import {CustomerWithID} from '../../models/allOrdersWithFilters'
import {EditProfile, useAuth} from '../../modules/auth'
import {UserProfile as UserProfileObj} from '../../models/models'
import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import {Form, Formik} from 'formik'
import {requestEditProfile} from '../../modules/auth/core/_requests'
import {useNavigateHelper} from '../../hooks/useNavigateHelper'
import * as Yup from 'yup'
import {usePaginationForWrapper} from '../../hooks/usePaginationForWrapper'
import {PaginationWrapper} from '../../components/PaginationWrapper'

export const NewOrderAdmin = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const navigateHelper = useNavigateHelper()
  const [clientSearch, setClientSearch] = useState('' as string)
  const {data: users = []} = useQuery<CustomerWithID[]>(
    ['allDistributors'],
    async () => {
      return requestAllDistributors().then(({data: x}) => {
        return x
      })
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!auth}
  )
  const {
    currentItems: paginatedUsers,
    handlePaginationNumberClick,
    handlePreviousClick,
    handleNextClick,
    chunkedArray,
    currentPage,
  } = usePaginationForWrapper(users, 4 * 4, clientSearch, 'customer')
  const {data: dataUser} = useQuery<UserProfileObj>(
    ['myProfile'],
    async () => {
      const {data: x} = await requestProfile()
      return x
    },
    {staleTime: 60 * 60 * 24 * 365}
  )
  if (!auth?.isAdmin) {
    return <>NOT ALLOWED</>
  }
  return (
    <Formik
      initialValues={{selectedClientSageCode: ''}}
      onSubmit={async (values) => {
        const sageCodeToSet = values.selectedClientSageCode
        const editProfileObj: EditProfile = dataUser as EditProfile
        editProfileObj.sageCode = sageCodeToSet
        await requestEditProfile(editProfileObj)
        auth.sageCode = sageCodeToSet
        navigateHelper('/orders')
      }}
      validationSchema={Yup.object({
        selectedClientSageCode: Yup.string().required(),
      })}
    >
      {({setFieldValue}) => (
        <Form placeholder={undefined}>
          <Row>
            <Col>
              <div className='d-flex flex-column w-100 mb-8'>
                <div className='position-relative w-100'>
                  <label htmlFor='' className='fs-4 fw-medium mb-4'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP1_INPUT_LABEL'})}
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3'
                    id='kt_datatable_search_query'
                    onChange={(e) => {
                      const value = e.target.value
                      setClientSearch(value)
                    }}
                  />
                  <span className='fw-normal' style={{color: '#99A1B7'}}>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP1_INPUT_TEXT_ADMIN'})}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                {paginatedUsers.length === 0 ? (
                  <p className='fs-3'>{intl.formatMessage({id: 'FETCH.NO_RESULTS'})}</p>
                ) : (
                  paginatedUsers.map((user: CustomerWithID) => (
                    <Col
                      lg='4'
                      xl='3'
                      className='mb-6 position-relative text-center d-flex'
                      key={user.id}
                    >
                      <input
                        type='radio'
                        className='position-absolute top-0'
                        style={{
                          transform: 'translate(16px, 18px)',
                          accentColor: '#0c2340',
                        }}
                        name='selectedClientSageCode'
                        value={user.sageCode}
                        id={`kt_order_form_client_${user.id.toString()}`}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center h-100 px-13 w-100`}
                        htmlFor={`kt_order_form_client_${user.id.toString()}`}
                        onClick={() => {
                          setFieldValue('selectedClientSageCode', user.sageCode)
                        }}
                      >
                        <div className='d-block m-auto'>
                          <h4 className='mb-0'>
                            {user.businessName} ({user.sageCode})
                          </h4>
                          <span className='text-start' style={{color: '#99A1B7'}}>
                            {user.email}
                          </span>
                        </div>
                      </label>
                    </Col>
                  ))
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                <PaginationWrapper
                  currentPage={currentPage}
                  chunkedArray={chunkedArray}
                  handleNextClick={handleNextClick}
                  handlePaginationNumberClick={handlePaginationNumberClick}
                  handlePreviousClick={handlePreviousClick}
                  key='pagination-clients'
                />
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type='submit'>{intl.formatMessage({id: 'GENERAL_SELECT'})}</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
