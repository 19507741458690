const formatPrice = (price:number):string => {
  return (
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(price)
  )
}

export { formatPrice }
