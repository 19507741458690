import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {requestPasswordReset} from '../core/_requests'
import {ResetPassword} from '../core/_models'
import {PasswordRegex} from '../../../helpers/utils'
import {useValidationTranslations} from '../../../helpers/useValidationTranslations'

const initialValues = {
  password: '',
  confirmPassword: '',
  email: '',
  token: '',
}

export function SetPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [apiResponse, setApiResponse] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    MessagePasswordRequired,
    MessagePasswordRequiredFormat,
    MessagePasswordConfirmationRequired,
    MessageEmailWrong,
    MessageEmailRequired,
    MessagePasswordAndPasswordConfirmationNotMatch,
    MessagePasswordLength,
  } = useValidationTranslations()
  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(10, MessagePasswordLength)
      .matches(PasswordRegex, MessagePasswordRequiredFormat)
      .required(MessagePasswordRequired),
    confirmPassword: Yup.string()
      .min(10, MessagePasswordLength)
      .matches(PasswordRegex, MessagePasswordRequiredFormat)
      .required(MessagePasswordConfirmationRequired)
      .oneOf([Yup.ref('password')], MessagePasswordAndPasswordConfirmationNotMatch),
    email: Yup.string().email(MessageEmailWrong).required(MessageEmailRequired),
    token: Yup.string().required(),
  })
  const getQueryString = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams
  }
  initialValues.token = getQueryString().get('token') as string
  initialValues.email = getQueryString().get('email') as string
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setIsSubmitted(false)
      window['showToastOnError'] = false
      try {
        const result = await requestPasswordReset(values as ResetPassword)
        const isOK = result.status >= 200 && result.status < 300
        if (isOK) {
          setHasErrors(false)
          setLoading(false)
          setApiResponse('')
          setIsSubmitted(true)
        }
      } catch (err: any) {
        const errorMessageFromAPI: string =
          err && err['response'] && err['response']['data']['message']
        if (errorMessageFromAPI) {
          setApiResponse(errorMessageFromAPI)
        }
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        formik.resetForm()
      }
    },
  })

  if (getQueryString().size <= 0) {
    return (
      <div>
        Url has expired.{' '}
        <Link to='/auth/forgot-password' className='text-decoration-underline'>
          Request again
        </Link>
      </div>
    )
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.SET_PASSWORD.DESC'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD_PLACEHOLDER'})}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent mb-5',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <p className='fs-6' style={{color: '#99A1B7'}}>
          {intl.formatMessage({id: 'AUTH.FORGOT.PASSWORD_TERMS'})}
        </p>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD_REPEAT_PLACEHOLDER'})}
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent mb-4',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {apiResponse && <span>{apiResponse}</span>}
            {!apiResponse && <span>{intl.formatMessage({id: 'AUTH.FORGOT.ERRORS'})}</span>}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{intl.formatMessage({id: 'AUTH.FORGOT.SUCCESS'})}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='d-grid mb-10'>
        {isSubmitted && (
          <Link to='/' className='text-success'>
            <button type='button' className={`btn btn-primary w-100`}>
              Go to login
            </button>
          </Link>
        )}
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || isSubmitted}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.FORGOT.BUTTON'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'FETCH.LOADING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
