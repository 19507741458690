import { Button } from "react-bootstrap";
import { useQuery } from "react-query";
import SingleNews from "./singleNews";
import { useState } from "react";
import { requestNews } from "../../requests/requests";
import {useIntl} from 'react-intl';
import { Link } from "react-router-dom";
import { usePagination } from "../../hooks/usePagination";
import AddNewsModal from "../../components/add-news/addNewsModal";

export function ManageNews() {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const newsPerPage = 6;

  const getTimeOfDate = (date?: Date) => {
    return date != null ? new Date(date).getTime() : 0;
  }

  // get data from requestNews 
  const { isLoading, error, data, refetch: refetchNews } = useQuery("manageNews", () => requestNews(), {
    refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5
  });

  const newsData = data && data.data && data.data.sort((a,b) => { return getTimeOfDate(b.dateCreated) - getTimeOfDate(a.dateCreated) });
  const { currentItems, handlePaginationNumberClick, handlePreviousClick, handleNextClick, chunkedArray  } = usePagination(newsData, newsPerPage);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <h1>
            {intl.formatMessage({id: 'NEWS.NEWS_TITLE'})}
          </h1>
          <span className="text-gray-400 pt-1 fw-semibold fs-6">
            {intl.formatMessage({id: 'NEWS.NEWS_SUBTITLE'})}
          </span>
        </div>
        <Button 
          className="my-2"  
          onClick={() => setShow(true)}
        >
          {intl.formatMessage({id: 'NEWS.ADD_NEWS'})}
        </Button>
      </div>
      <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9 mt-4">
        <section className="card-body p-lg-20">
          <div className="d-flex flex-stack mb-5">
            <h2 className="mb-7">
              {intl.formatMessage({id: 'NEWS.LAST_NEWS'})}
            </h2>
          </div>
          <div className="separator separator-dashed mb-9"></div>
          <div className='row gx-10 gy-10'>
            {
              isLoading
              ? <p>{intl.formatMessage({id: 'FETCH.LOADING'})}</p>
              : error
              ? <p>{intl.formatMessage({id: 'FETCH.ERROR'})}</p>            
              : currentItems?.map((article: any) => (
                <SingleNews 
                  id={article.id}
                  title={article.title}
                  content={article.description}
                  image={article.urlImage}
                  enable={article.enable}
                  lang={article.lang}
                  date={article.dateCreated}
                  externalLink={article.externalLink}
                  key={article.id}
                  refetchNews={refetchNews}
                />
              ))
            }
          </div>
          <AddNewsModal show={show} onHide={() => setShow(false)} refetch={refetchNews} />    
        </section>

      {/* PAGINATION */}
      <section className="mb-8">
        <ul className="pagination">
          <li 
            className="page-item previous"
            onClick={() => handlePreviousClick()}
          >
            <Link to={'#'} className="page-link">
              <i className="previous" />
            </Link>
          </li>

          {
            chunkedArray &&
            chunkedArray?.map((_newsChunk: any, index: number) => (
              <li 
                onClick={() => handlePaginationNumberClick(index + 1)}
                key={index}
                className={`page-item ${index === 0 ? 'active' : ''}`}
              >
                <Link to={'#'} className="page-link">
                  {index + 1}
                </Link>
              </li>
            ))
          }
      
          <li 
            className="page-item next"
            onClick={() => handleNextClick()}  
          >
            <Link to={'#'} className="page-link">
              <i className="next" />
            </Link>
          </li>
        </ul>
      </section>
      </div>
    </>
  )
}
