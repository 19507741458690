import {Modal, Button, Form} from 'react-bootstrap'
import {Field, Formik} from 'formik'
import {useIntl} from 'react-intl'
import {Product} from '../../models/models'
import {MyOrder, MyOrderLine} from '../../models/myOrders'
import {requestModifyOrder} from '../../requests/requests'
import {ToastifyCaller, ToastifyType} from '../Toastify'
import {formatPrice} from '../../helpers/formatPrice'
import {OrderEdit} from '../../models/orderEdit'
import {getProductFormat} from '../../hooks/getProductFormat'
import {useProductsAvailables} from '../../hooks/useProductsAvailables'
import {useState} from 'react'
import { useSelectedExpeditionDate } from '../../hooks/useSelectedExpeditionDate'

export const EditOrderModal = ({
  Order,
  show,
  onHide,
  refetch,
}: {
  Order: MyOrder
  show: MyOrderLine | undefined
  onHide: any
  refetch: any
}) => {
  const intl = useIntl()
  const selectedExpeditionDate = useSelectedExpeditionDate(Order.expeditionDate);
  const {availableProducts} = useProductsAvailables(Order.clientId, selectedExpeditionDate)
  const [updatingOrder, setUpdatingOrder] = useState(false)
  const similarProducts = (product: Product): Product[] => {
    const similarProductsOrdered = [...availableProducts]
    similarProductsOrdered.sort((a, b) => {
      return a.stockRatio - b.stockRatio
    })
    return similarProductsOrdered.filter((x) => x.code === product.code)
  }
  const IdentifyProduct = (code, packageUnit): Product => {
    const products = availableProducts.filter(
      (x) => x.code === code && x.packageUnit === packageUnit
    )
    if (products) {
      return products[0]
    } else {
      return {} as Product
    }
  }
  if (!show) {
    return <></>
  }
  const OrderLine = show
  const SimilarProducts: Product[] = similarProducts(
    IdentifyProduct(OrderLine?.code, OrderLine?.packageUnit)
  )

  return (
    <Modal
      show={show !== undefined}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered'
    >
      <Modal.Header
        className='border-0 position-absolute top-0 end-0 z-index-10'
        closeButton
        style={{zIndex: 100}}
      ></Modal.Header>
      <Modal.Body className='modal-body py-lg-5 px-lg-10'>
        <Formik
          initialValues={{
            stockRatio: OrderLine?.stockRatio ? OrderLine?.stockRatio : 0,
            quantity: OrderLine?.quantity ? OrderLine?.quantity : 0,
            packageUnit: OrderLine?.packageUnit ? OrderLine?.packageUnit : 0,
          }}
          onSubmit={async ({stockRatio, quantity, packageUnit}) => {
            setUpdatingOrder(true)
            const orderEditObj = {
              guidId: Order.guidId,
              clientId: Order.clientId,
              expeditionDate: Order.expeditionDate,
              detail: Order.detail,
              lines: Order.lines,
            } as OrderEdit
            const orderLine = orderEditObj.lines.find(
              (x) => x.code === OrderLine?.code && x.packageUnit === OrderLine?.packageUnit
            )
            if (orderLine) {
              orderLine.stockRatio = stockRatio
              orderLine.quantity = quantity
              orderLine.packageUnit = packageUnit as string
            }
            await requestModifyOrder(orderEditObj)
            await refetch()
            setUpdatingOrder(false)
            onHide()
            ToastifyCaller(
              intl.formatMessage({id: 'ORDERS.CREATE_ORDER_UPDATED'}),
              ToastifyType.SUCCESS
            )
          }}
        >
          {({handleSubmit, values}) => (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex align-items-center justify-content-start'>
                <img
                  src={OrderLine?.urlImage}
                  className='img-fluid d-block me-5 rounded'
                  style={{height: 100, width: 100}}
                  alt='Product Thumbnail'
                ></img>
                <div>
                  <div className='fw-bold text-primary'>{OrderLine?.description}</div>
                  <div className='fs-7 text-muted'>{OrderLine?.code}</div>
                </div>
              </div>
              <div className='table-responsive mt-3 border-bottom'>
                <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted text-uppercase'>
                      <th>{intl.formatMessage({id: 'CART.ITEM_PACKAGE_FORMAT'})}</th>
                      <th>{intl.formatMessage({id: 'CART.ITEM_QUANTITY'})}</th>
                      <th>
                        <span>€/{getProductFormat(OrderLine?.packageUnit as string)}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {OrderLine?.stockUnit === OrderLine?.salesUnit && (
                          <>
                            {OrderLine?.packageUnit === 'PZA' && (
                              <>
                                <span className='ms-3 ms-lg-0'>
                                  {getProductFormat(OrderLine?.packageUnit as string)}{' '}
                                  {intl.formatMessage({id: 'GENERAL.OF'}).toUpperCase()}{' '}
                                </span>
                                <Field
                                  type='number'
                                  name='stockRatio'
                                  className='form-control ms-3 d-inline-block my-3 my-lg-0'
                                  style={{width: '120px', maxWidth: '100%'}}
                                ></Field>{' '}
                                <span className='ms-3'>{OrderLine?.salesUnit}</span>
                              </>
                            )}
                            {OrderLine?.packageUnit === 'CAJ' && (
                              <div>
                                {getProductFormat(
                                  OrderLine?.packageUnit as string,
                                  OrderLine?.stockRatio,
                                  intl.formatMessage({id: 'GENERAL.OF'}),
                                  OrderLine.code
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {OrderLine?.stockUnit !== OrderLine?.salesUnit && (
                          <>
                            {getProductFormat(OrderLine?.packageUnit as string)}
                            <Form.Select
                              aria-label={intl
                                .formatMessage({id: 'CART.ITEM_SELECT_FORMAT'})
                                .toUpperCase()}
                              className='ms-3 d-inline-block w-50'
                              onChange={(e) => {
                                values.stockRatio = parseInt(e.target.value)
                                const selectedOpt = e.target.options[e.target.selectedIndex]
                                const packageUnitSelected =
                                  selectedOpt.getAttribute('data-packageunit')
                                if (packageUnitSelected != null) {
                                  values.packageUnit = packageUnitSelected
                                }
                              }}
                              style={{height: '40px'}}
                              defaultValue={OrderLine?.stockRatio}
                            >
                              {SimilarProducts.map((x) => {
                                return (
                                  <option
                                    key={x.stockRatio}
                                    data-stockratio={x.stockRatio}
                                    data-packageunit={x.packageUnit}
                                    data-code={x.code}
                                    value={x.stockRatio}
                                  >
                                    {x.stockRatio}
                                  </option>
                                )
                              })}
                            </Form.Select>
                            <span className='ms-3'>{OrderLine?.stockUnit}</span>
                          </>
                        )}
                      </td>
                      <td>
                        <Field
                          type='number'
                          name='quantity'
                          className='form-control'
                          style={{width: '120px', maxWidth: '100%'}}
                        ></Field>
                      </td>
                      <td>
                        {formatPrice(OrderLine?.netPricePerUnit ? OrderLine?.netPricePerUnit : 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='d-flex'>
                <Button
                  variant='secondary'
                  type='reset'
                  className={`w-100 mt-8 ${updatingOrder ? 'disabled' : ''}`}
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button
                  variant='primary'
                  type='submit'
                  className={`w-100 mt-8 ms-3 ${updatingOrder ? 'disabled' : ''}`}
                >
                  {intl.formatMessage({id: 'BUTTON.ACCEPT'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
