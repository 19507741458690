import { FC, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { requestAllDistributors, requestAllOrdersWithFilters } from '../../requests/requests'
import { useAuth } from '../../modules/auth'
import { AllOrdersWithFilterModel, CustomerWithID } from '../../models/allOrdersWithFilters'
import { useIntl } from 'react-intl'
import { AllOrdersWithFiltersTable } from '../../modules/orders/AllOrdersWithFiltersTable'
import { configStaleTime } from '../../constants'
import { formatDateType } from '../../helpers/formatDate'

const ViewAllOrdersWithFilters: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const queryClient = useQueryClient()
  const [filteredDate, setFilteredDate] = useState<Date | undefined>()
  const [filteredUser, setFilteredUser] = useState('')
  const { isFetching, refetch } = useQuery<AllOrdersWithFilterModel>(
    ['allOrders'],
    async () => {
      if (auth) {
        return requestAllOrdersWithFilters(
          filteredUser ? filteredUser : undefined,
          filteredDate ? formatDateType(filteredDate, 'en-CA') : undefined
        ).then(({ data: x }) => {
          return x
        })
      } else {
        return {} as AllOrdersWithFilterModel
      }
    },
    { staleTime: configStaleTime, enabled: !!auth } // 30s of staleTime
  )
  useQuery<CustomerWithID[]>(
    ['allDistributors'],
    async () => {
      return requestAllDistributors().then(({ data: x }) => {
        return x
      })
    },
    { staleTime: 60 * 60 * 24 * 365, enabled: !!auth }
  )
  const allOrders: AllOrdersWithFilterModel = queryClient.getQueryData([
    'allOrders',
  ]) as AllOrdersWithFilterModel
  const allUsers: CustomerWithID[] = queryClient.getQueryData([
    'allDistributors',
  ]) as CustomerWithID[]

  return (
    <>
      {allOrders && (
        <AllOrdersWithFiltersTable
          className=''
          headerTitle={intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_TITLE' })}
          headerDescription={intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_DESCRIPTION' })}
          orders={allOrders}
          headers={[
            intl.formatMessage({ id: 'GENERAL_ORDER_CREATION' }),
            intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_CUSTOMER' }),
            intl.formatMessage({ id: 'GENERAL_DELIVERY_ADDRESS' }),
            intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_DELIVERY_DATE' }),
            // intl.formatMessage({id: 'ALL_ORDERS_WITH_FILTERS_HEADER_COMPLETE_ORDER'}),
            //intl.formatMessage({id: 'ALL_ORDERS_WITH_FILTERS_HEADER_SPLIT_PIECE'}),
            intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_STATUS' }),
            intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_DELIVERY_NOTE' }),
            intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_OPTIONS' }),
          ]}
          customers={allUsers}
          isFetching={isFetching}
          refetch={refetch}
          filteredUser={filteredUser}
          setFilteredUser={setFilteredUser}
          filteredDate={filteredDate}
          setFilteredDate={setFilteredDate}
        ></AllOrdersWithFiltersTable>
      )}
      {!allOrders && (
        <div className={`card`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                {intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_TITLE' })}
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_HEADER_DESCRIPTION' })}
              </span>
            </h3>
          </div>
          {/* end::Header */}
          <div className='card-body py-3'>
            <div>
              <i className='fa fa-spinner fs-2x fa-sync fa-spin'></i>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { ViewAllOrdersWithFilters }
