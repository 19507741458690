import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Orders} from '../pages/orders/NewOrder'
import {MyOrders} from '../pages/orders/MyOrders'
import {ViewAllOrders} from '../pages/orders/ValidateOrders'
import {News} from '../pages/news/PublicNews'
import {MyOrderDetail} from '../pages/orders/MyOrderDetail'
import {ViewAllOrdersWithFilters} from '../pages/orders/ViewAllOrdersWithFilters'
import {Categories} from '../pages/categories/listCategories'
import {ProductExtensions} from '../pages/product-extensions/listProductExtensions'
import {ManageNews} from '../pages/manageNews/manageNews'
import {UserProfile} from '../pages/userprofile/userProfile'
import {OrderEdit} from '../pages/orders/OrderEdit'
import {NewOrderAdmin} from '../pages/orders/NewOrderAdmin'
import {OrdersGroupedByAddress} from '../pages/orders/OrdersGroupedByAddress'
import { ExportOrders } from '../pages/orders/ExportOrders'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/orders' element={<Orders />} />
        <Route path='/my-orders' element={<MyOrders />} />
        <Route path='/export-orders' element={<ExportOrders />} />
        <Route path='/orders-by-address' element={<OrdersGroupedByAddress />} />
        <Route path='/my-order-detail/:orderId' element={<MyOrderDetail />} />
        <Route path='/validate-orders' element={<ViewAllOrders />} />
        <Route path='/validate-orders' element={<ViewAllOrders />} />
        <Route path='/all-orders-with-filters' element={<ViewAllOrdersWithFilters />} />
        <Route path='/order-detail/:orderId' element={<MyOrderDetail />} />
        <Route path='/order-edit/:orderId' element={<OrderEdit />} />
        <Route path='/news' element={<News />} />
        <Route path='/manage-news' element={<ManageNews />} />
        <Route path='/categories' element={<Categories />} />
        <Route path='/products' element={<ProductExtensions />} />
        <Route path='/dashboard' element={<DashboardWrapper />} />
        <Route path='/my-profile' element={<UserProfile />} />
        {/* Pre step for New Order when are admin */}
        <Route path='/new-order' element={<NewOrderAdmin />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
