export const pdfWindowLauncher = (base64DocumentPDF) => {
  const pdfWindow = window.open('#pdf')
  if (pdfWindow) {
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${base64DocumentPDF}' style="border:none;" type="application/pdf"></iframe>`
    )
    pdfWindow.document.title = 'PDF'
    pdfWindow.document.body.style.margin = '0'
    pdfWindow.document.body.style.overflow = 'hidden'
  }
}
