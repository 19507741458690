/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {formatPrice} from '../helpers/formatPrice'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
import {useIntl} from 'react-intl'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  className: string
  topProducts: TopProductItem[]
}

export type TopProductItem = {
  code: string
  description: string
  packageUnit: string
  totalNetPrice: number
  totalStockRatio: number
}

export const TopProductsCard: FC<Props> = ({className, topProducts}) => {
  const intl = useIntl()
  const totalNetPrice = topProducts.reduce((acc, x) => acc + x.totalNetPrice, 0)
  const totalStockRatio = topProducts.reduce((acc, x) => acc + x.totalStockRatio, 0)
  const productsMapped = topProducts.map((x) => {
    return {
      total: x.totalNetPrice,
      totalStock: x.totalStockRatio,
      percent: (x.totalStockRatio / totalStockRatio) * 100,
      description: x.description,
    }
  })

  const data = {
    labels: productsMapped.map((x) => x.description),
    datasets: [
      {
        label: intl.formatMessage({id: 'DASHBOARD.TOP_PRODUCTS_CARD.LABEL'}),
        data: productsMapped.map((x) => x.totalStock),
        backgroundColor: [
          getCSSVariableValue('--bs-primary'),
          getCSSVariableValue('--bs-secondary'),
          getCSSVariableValue('--bs-success'),
          getCSSVariableValue('--bs-danger'),
          getCSSVariableValue('--bs-warning'),
        ],
        borderColor: [
          getCSSVariableValue('--bs-primary'),
          getCSSVariableValue('--bs-secondary'),
          getCSSVariableValue('--bs-success'),
          getCSSVariableValue('--bs-danger'),
          getCSSVariableValue('--bs-warning'),
        ],
        borderWidth: 1,
        legendCallback: (e) => {
          console.log(e)
        },
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     label: (context) => {
      //       return `${context.parsed} €`;
      //     }
      //   }
      // }
    },
  }

  return (
    <div className={`card card-flush h-100 ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {formatPrice(totalNetPrice).replace('€', '')}
            </span>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>€</span>

            {/* <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 0.1%
            </span> */}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            {intl.formatMessage({id: 'DASHBOARD.TOP_PRODUCTS_CARD.TITLE'})}
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        {topProducts.length <= 0 && (
          <span className='text-muted align-self-start'>
            {intl.formatMessage({id: 'GENERAL.NO_DATA_AVAILABLE'})}
          </span>
        )}
        {topProducts.length > 0 && (
          <div className='d-flex flex-center me-5 pt-2 pr-5' style={{width: '160px'}}>
            <Doughnut data={data} options={options} />
          </div>
        )}

        <div className='d-flex flex-column content-justify-center flex-row-fluid mt-lg-5'>
          {productsMapped.map((x, i) => {
            let color = getCSSVariableValue('--bs-primary')
            switch (i) {
              case 0: {
                color = getCSSVariableValue('--bs-primary')
                break
              }
              case 1: {
                color = getCSSVariableValue('--bs-secondary')
                break
              }
              case 2: {
                color = getCSSVariableValue('--bs-success')
                break
              }
              case 3: {
                color = getCSSVariableValue('--bs-danger')
                break
              }
              case 4: {
                color = getCSSVariableValue('--bs-warning')
                break
              }
            }
            return (
              <div
                className={`d-flex fw-semibold align-items-center ${i > 0 ? 'my-3' : ''}`}
                key={x.description}
              >
                <div
                  className='bullet w-8px h-3px rounded-2 me-3'
                  style={{backgroundColor: color}}
                ></div>
                <div className='text-gray-500 flex-grow-1 me-4'>{x.description}</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{formatPrice(x.total)}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
