import {useQuery} from 'react-query'
import {useAuth} from '../modules/auth'
import {customerClients} from '../requests/requests'
import {Client} from '../models/models'

export const useGetMyClients = () => {
  const {auth} = useAuth()
  const {data: myClients} = useQuery<Client[]>(
    [`allMyClients${auth?.sageCode}`],
    async () => {
      return customerClients().then(({data: x}) => {
        return x
      })
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!auth}
  )
  const myClientsOptions = () => {
    if (!myClients) {
      return {}
    }
    return [...myClients].map((element) => ({
      value: element.id,
      label: `${element.name}`,
    }))
  }
  return {myClientsOptions, myClients}
}
